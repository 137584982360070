import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Timeline,
  HR,
} from '@logtrade-technology-ab/logtrade-react-components'
import { dateAndTime } from 'utils'

const accessHistory = (lock) =>
  lock.accessHistory.map(({ request, response, testMode }) => ({
    status: response
      ? response.accessGranted
        ? 'success'
        : 'error'
      : 'neutral',
    request: request.actionName,
    response: response
      ? response.accessGranted
        ? 'Granted'
        : 'Denied'
      : 'Pending...',
    testMode,
    reason: response?.denialReason,
    timestamp: response?.recievedAt ?? request.sentAt,
  }))

const LockAccessModal = ({ lock, onClose, ...props }) => {
  return (
    <Modal
      title={`Access History for ${lock.assetIdentifier}`}
      onClose={onClose}
      isDismissable
      {...props}
    >
      <HR />
      <div
        autoComplete="off"
        className="grid p-8 !pb-10 max-h-[400px] overflow-y-auto"
      >
        <Timeline>
          {accessHistory(lock).map(
            ({ request, response, timestamp, status, reason, testMode }) => (
              <Timeline.Item
                key={timestamp}
                stamp={dateAndTime(timestamp)}
                title={request}
                text={
                  testMode
                    ? 'Test mode'
                    : reason
                    ? `${response}: ${reason}`
                    : response
                }
                status={testMode ? 'neutral' : status}
              />
            )
          )}
        </Timeline>
      </div>
    </Modal>
  )
}

LockAccessModal.propTypes = {
  user: PropTypes.object,
  ...Modal.propTypes,
}

export default LockAccessModal
