import { graphql } from 'gql'

export const CreateUserMutation = graphql(`
  mutation CreateUser($data: CreateUserInput!) {
    createUser(data: $data) {
      ...UserFragment
    }
  }
`)

export const RemoveUserMutation = graphql(`
  mutation RemoveUser($data: RemoveUserInput!) {
    removeUser(data: $data) {
      ...UserFragment
    }
  }
`)

export const ConnectOrganicationToIolMutation = graphql(`
  mutation ConnectOrganizationToIol($data: ConnectOrganizationToIolInput!) {
    connectOrganizationToIol(data: $data) {
      connectionUrl
    }
  }
`)

export const SaveOrganizationSettingsMutation = graphql(`
  mutation UpdateOrganizationSettings($data: UpdateOrganizationSettingsInput!) {
    updateOrganizationSettings(data: $data) {
      disconnectFromSensor
      disconnectFromLocationIOLId
    }
  }
`)
