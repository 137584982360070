import {
  AssociateVehicleWithGatewayMutation,
  CreateVehicleMutation,
  UpdateVehicleMutation,
  RemoveVehicleMutation,
} from './mutations'
import { GetVehicleQuery, GetVehiclesQuery } from './queries'
import { withReport } from 'api/utils'
import { useMutation, useQuery } from '@apollo/client'
import { GetGatewaysQuery } from 'api/gateways'
import { MutationOptions, QueryOptions } from 'api/types'
export * from './queries'
export * from './mutations'

const api = {
  // Get single vehicle by ID
  getById: (
    { id }: { id: string },
    options: QueryOptions<typeof GetVehicleQuery>
  ) => {
    const state = useQuery(GetVehicleQuery, {
      ...(options as object),
      variables: { id },
    })
    const vehicle = state.data?.vehicle

    return [vehicle, state] as const
  },

  // Get all vehicles
  getAll: (options: QueryOptions<typeof GetVehiclesQuery>) => {
    const state = useQuery(GetVehiclesQuery, options as unknown)
    const vehicles = state.data?.vehicles ?? []

    return [vehicles, state] as const
  },

  // Create new vehicle
  create: withReport<typeof CreateVehicleMutation>()((addReport) => {
    const [mutate, state] = useMutation(CreateVehicleMutation, {
      refetchQueries: [{ query: GetVehiclesQuery }],
    })

    const call = (
      data: {
        model: string
        manufacturer: string
        uniqueIdentifier: string
        assetIdentifier: string
        description: string
        name: string
      },
      options: MutationOptions<typeof CreateVehicleMutation>
    ) => {
      const opts = addReport('Truck created', options)

      return mutate({ ...opts, variables: { data } })
    }

    return [call, state] as const
  }),

  // Associate vehicle with gateway
  associateVehicleWithGateway: withReport<
    typeof AssociateVehicleWithGatewayMutation
  >()((addReport) => {
    const [mutate, state] = useMutation(AssociateVehicleWithGatewayMutation, {
      refetchQueries: [
        { query: GetVehiclesQuery },
        { query: GetGatewaysQuery },
      ],
    })

    const call = (
      {
        gateway,
        vehicle,
      }: {
        gateway: { iolIdentity: string }
        vehicle: { iolIdentity: string }
      },
      options: MutationOptions<typeof AssociateVehicleWithGatewayMutation>
    ) => {
      const data = {
        vehicleIolIdentity: vehicle?.iolIdentity,
        gatewayIolIdentity: gateway?.iolIdentity,
      }

      const opts = addReport('Truck associated with gateway', options)

      return mutate({ ...opts, variables: { data } })
    }

    return [call, state] as const
  }),

  // Update existing vehicle
  update: withReport<typeof UpdateVehicleMutation>()((addReport) => {
    const [mutate, state] = useMutation(UpdateVehicleMutation, {
      refetchQueries: [{ query: GetVehiclesQuery }],
    })

    const call = (
      data: {
        id: string
        model?: string
        manufacturer?: string
        uniqueIdentifier?: string
        assetIdentifier?: string
        description?: string
        name?: string
      },
      options: MutationOptions<typeof UpdateVehicleMutation>
    ) => {
      const opts = addReport('Truck updated', options)
      return mutate({ ...opts, variables: { data } })
    }

    return [call, state] as const
  }),

  // Remove existing vehicle
  remove: withReport<typeof RemoveVehicleMutation>()((addReport) => {
    const [mutate, state] = useMutation(RemoveVehicleMutation, {
      refetchQueries: [{ query: GetVehiclesQuery }],
    })

    const call = (
      { id }: { id: string },
      options: MutationOptions<typeof RemoveVehicleMutation>
    ) => {
      const opts = addReport('Truck removed', options)

      return mutate({ ...opts, variables: { data: { assetId: id } } })
    }

    return [call, state] as const
  }),

  // Create or update vehicle based on flag
  crup: (
    updateFlag = false,
    options?: MutationOptions<typeof CreateVehicleMutation> &
      MutationOptions<typeof UpdateVehicleMutation>
  ) => {
    const create = api.create(options)
    const update = api.update(options)
    return updateFlag ? update : create
  },
}

export default api
