import React from 'react'
import { SideMenu as LTSideMenu } from '@logtrade-technology-ab/logtrade-react-components'
import useAppRoutes from 'useAppRoutes'

const SideMenu = () => {
  const appRoutes = useAppRoutes(true)

  return (
    <LTSideMenu>
      {appRoutes.map(({ name, path, children }) => (
        <React.Fragment key={path}>
          <LTSideMenu.Header>{name}</LTSideMenu.Header>
          {children?.map((page) => (
            <LTSideMenu.Item key={page.path} to={page.path}>
              {page.name}
            </LTSideMenu.Item>
          ))}
        </React.Fragment>
      ))}
      <LTSideMenu.Item to="/logout" className="mt-auto mb-5">
        Logout
      </LTSideMenu.Item>
    </LTSideMenu>
  )
}

export default SideMenu
