import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Text } from '@logtrade-technology-ab/logtrade-react-components'
import { theme } from 'utils'

const { colors } = theme

// eslint-disable-next-line react/prop-types
const StatReport = ({ className, icon, title, subtitle, value, status }) => (
  <div
    className={twMerge(
      'flex items-center gap-4 border-main-7 border-b py-3',
      className
    )}
  >
    {icon}
    <div className="flex flex-col grow gap-1">
      <Text
        className="font-normal"
        style={{ color: status && colors.status[status][1] }}
      >
        {title}
      </Text>
      {subtitle && <Text className="text-main-5 text-xs">{subtitle}</Text>}
    </div>
    <Text
      className="font-normal"
      style={{ color: status && colors.status[status][1] }}
    >
      {value}
    </Text>
  </div>
)

export default StatReport
