import React from 'react'
import { Pane, Loader } from '@logtrade-technology-ab/logtrade-react-components'

const Loading = () => (
  <Pane className="flex justify-center items-center grow">
    <Loader className="h-12" />
  </Pane>
)

export default Loading
