import React, { createContext, useState, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

const ReportContext = createContext({
  reports: {},
  getReports: () => null,
  addReport: (_report) => null,
  dismiss: (_id) => null,
})

// eslint-disable-next-line react/prop-types
export const ReportProvider = ({ children }) => {
  const [reports, setReports] = useState({})

  const addReport = (report) =>
    setReports((reports) => {
      const { type } = report
      const existing = reports[type] ?? []

      return {
        ...reports,
        [type]: [
          ...existing,
          { ...report, timestamp: new Date(), id: uuidv4() },
        ],
      }
    })

  const dismiss = (id) =>
    Object.keys(reports).forEach((type) =>
      setReports((reports) => ({
        ...reports,
        [type]: reports[type]?.filter((report) => report.id !== id),
      }))
    )

  const getReports = (target, timeout = 5000) => {
    const types = _.castArray(target).map((t) => t.type)

    const selection = _.flatMap(types, (type) => reports[type] ?? [])

    const [last, ...rest] = selection.sort((x, y) => x.timestamp - y.timestamp)
    if (timeout && last) setTimeout(() => dismiss(last.id), timeout)

    return {
      report: last,
      waiting: rest.length,
    }
  }

  return (
    <ReportContext.Provider value={{ reports, getReports, dismiss, addReport }}>
      {children}
    </ReportContext.Provider>
  )
}

export const useReportState = () => useContext(ReportContext)
export const useReports = (...args) => useReportState().getReports(...args)

export const ReportConsumer = ReportContext.Consumer
