import { graphql } from 'gql'

graphql(`
  fragment VolvoVehicleFragment on RemoteVehicleOutput {
    uniqueIdentifier
    name
    manufacturer
    model
    connectorAsset {
      id
      assetId
      fetchActive
      geoLocation {
        lat
        lng
      }
      lastTimestamp
    }
  }
`)

export const GetVolvoVehiclesQuery = graphql(`
  query VolvoVehicles {
    volvoVehicles {
      ...VolvoVehicleFragment
    }
  }
`)
