import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Modal,
  Text,
  HR,
  Button,
  TextArea,
  TextField,
} from '@logtrade-technology-ab/logtrade-react-components'
import { FormError } from 'components/elements'
import { z } from 'zod'
import api from 'api'

const createSchema = z.object({
  model: z.string().min(1, {
    message: 'Model is required',
  }),
  manufacturer: z.string().min(1, {
    message: 'Manufacturer is required',
  }),
  uniqueIdentifier: z.string().min(1, {
    message: 'VIN is required',
  }),
  assetIdentifier: z.string().min(1, {
    message: 'Reg. number is required',
  }),
  description: z.string().optional(),
  name: z.string().optional(),
})

const updateSchema = z
  .object({
    id: z.union([z.string(), z.number()]),
  })
  .and(createSchema.partial())

const ManualTruck = ({ truck, onClose, setTitle }) => {
  const [handleTruck, { loading }] = api.Vehicles.crup(truck, {
    onError: ({ message }) => setError('request', { message }),
    onCompleted: onClose,
  })

  useEffect(() => setTitle('Add a new truck'), [setTitle])

  const schema = truck ? updateSchema : createSchema

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm(
    truck && {
      defaultValues: truck,
    }
  )

  const onSubmit = (input) => {
    const { error, success, data } = schema.safeParse(input)

    if (error)
      error.issues.forEach(({ path: [field], message }) =>
        setError(field, { message })
      )

    return success && handleTruck(data)
  }

  return (
    <>
      <HR />
      <form
        className="grid p-8 mb-3 _md:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text className="col-span-full !font-normal">Required</Text>
        <TextField.Control
          control={control}
          name="manufacturer"
          placeholder="Manufacturer"
        />
        <TextField.Control control={control} name="model" placeholder="Model" />
        <TextField.Control
          control={control}
          name="assetIdentifier"
          placeholder="Registration Number"
        />
        <TextField.Control
          control={control}
          name="uniqueIdentifier"
          placeholder="VIN Vehicle identification number"
        />
        <Text className="col-span-full !font-normal">Optional</Text>
        <TextField.Control
          control={control}
          name="name"
          placeholder="Nickname"
        />
        <TextArea.Control
          control={control}
          name="description"
          placeholder="Description..."
          rows={4}
        />
        <FormError className="col-span-full" error={errors.request} />
        <Button
          type="submit"
          isLoading={loading}
          onPress={() => clearErrors('request')}
          className="col-span-full"
        >
          {truck ? 'Update truck' : 'Add truck'}
        </Button>
      </form>
    </>
  )
}

ManualTruck.propTypes = {
  truck: PropTypes.object,
  ...Modal.propTypes,
}

export default ManualTruck
