import React from 'react'
import {
  Text,
  PropertyRow,
} from '@logtrade-technology-ab/logtrade-react-components'
import api from 'api'

const SettingsPage = () => {
  const [profile] = api.Profile.get()

  const properties = [
    {
      label: 'CG Identity',
      value: profile.id,
    },
    { label: 'Name', value: [profile.firstName, profile.lastName].join(' ') },
    { label: 'Email', value: profile.email },
    { label: 'Role', value: profile.role },
  ]

  return (
    <div className="p-4 xl:!p-12 flex flex-col gap-16">
      <Text tag="h3">Settings</Text>
      <PropertyRow className="w-full max-w-7xl" data={properties} />
    </div>
  )
}

export default SettingsPage
