import React from 'react'
import ErrorBoundary from 'features/ErrorBoundary'

// eslint-disable-next-line react/prop-types
const ViewWrapper = ({ children }) => (
  <ErrorBoundary>
    <div className="grid grow relative p-6">{children}</div>
  </ErrorBoundary>
)

export default ViewWrapper
