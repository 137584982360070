import bezierSpline from '@turf/bezier-spline'
import rotate from '@turf/transform-rotate'
import scale from '@turf/transform-scale'
import _ from 'lodash'

// Logtrade address to mapbox address
export const toMapboxAddress = (ltAddress) => ({
  place: ltAddress.city,
  address_line1: ltAddress.address1,
  postcode: ltAddress.zipCode,
  country_code: ltAddress.countryCode,
  region: ltAddress.province,
  coords: {
    longitude: ltAddress.geoLocation.lng,
    latitude: ltAddress.geoLocation.lat,
  },
  description: `${ltAddress.zipCode}, ${ltAddress.city}`,
})

// Mapbox address to logtrade address
export const toLogtradeAddress = (mbAddress) => ({
  city: mbAddress?.place,
  address1: mbAddress?.address_line1,
  zipCode: mbAddress?.postcode,
  countryCode: mbAddress?.country_code,
  province: mbAddress?.region,
  geoLocation: {
    lat: mbAddress?.coords?.latitude,
    lng: mbAddress?.coords?.longitude,
  },
})

// Convert between array and object representations
export const convertPoint = (point) =>
  Array.isArray(point)
    ? { longitude: point[0], latitude: point[1] }
    : [point.longitude, point.latitude]

// Logtrade geoLocation(s) to geoJSON linestring or point
// https://www.rfc-editor.org/rfc/rfc7946
export const toGeoJson = (pointOrPoints) => {
  const points = _.castArray(pointOrPoints)
  const uniquePoints = _.uniqBy(points, ({ lat, lng }) => `${lat}${lng}`)

  return uniquePoints.length > 1
    ? {
        type: 'LineString',
        coordinates: uniquePoints.map((point) => [point.lng, point.lat]),
      }
    : {
        type: 'Point',
        coordinates: [points[0].lng, points[0].lat],
      }
}

// Logtrade geoLocations to geoJSON feature collection
export const toFeatureCollection = (points) => ({
  type: 'FeatureCollection',
  features: points.map((p) => ({
    type: 'Feature',
    geometry: toGeoJson(p),
  })),
})

// Get middle point of Logtrade geoLocations (returns mapBox format)
export const getMiddle = (geos) =>
  geos.reduce(
    (acc, curr) => ({
      latitude: acc.latitude + curr.lat / geos.length,
      longitude: acc.longitude + curr.lng / geos.length,
    }),
    { latitude: 0, longitude: 0 }
  )

// Get a point in the middle of two locations offset by a value
export const getCurvePoint = ([src, dst], curveScale = 0.3) => {
  // Distance to middle
  const dX = (dst.lng - src.lng) / 2
  const dY = (dst.lat - src.lat) / 2

  // Middle point between src and dst
  const middle = { lat: src.lat + dY, lng: src.lng + dX }

  // Vector from src to middle point
  const middleLine = toGeoJson([src, middle])

  // Rotate the vector (pivot from middle)
  const rotated = rotate(middleLine, src.lng > dst.lng ? -90 : 90, {
    pivot: toGeoJson(middle),
  })

  // Scale the vector (origin middle)
  const scaled = scale(rotated, curveScale, {
    origin: toGeoJson(middle),
    mutate: true,
  })

  // Curve point is end of scaled vector
  return {
    lng: scaled.coordinates[0][0],
    lat: scaled.coordinates[0][1],
  }
}

// Get curved line between src and dst points
export const curveLine = ([src, dst], curveScale = 0.3, sharpness = 1.1) => {
  const curvePt = getCurvePoint([src, dst], curveScale)

  // Add curve point
  const lineString = toGeoJson([src, curvePt, dst])

  // Curve line between the three points
  const curved = bezierSpline(lineString, {
    sharpness,
    resolution: 10000,
  })

  return curved
}

// -------------- 2d vector utilities --------------

// Magnitude of 2d vector
export const magnitude = (x, y) => Math.sqrt(x * x + y * y)
// Normalize a 2d vector
export const normalize = (x, y) => ({
  x: x / magnitude(x, y),
  y: y / magnitude(x, y),
})
