import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  HR,
  Button,
  Anttable,
} from '@logtrade-technology-ab/logtrade-react-components'
import api from 'api'
import { z } from 'zod'

const schema = z.object({
  activate: z.array(
    z.object({
      uniqueIdentifier: z.string(),
      name: z.string(),
      manufacturer: z.string(),
      model: z.string(),
    })
  ),
  deactivate: z.array(
    z.object({
      connectorAsset: z.object({
        assetId: z.string(),
      }),
    })
  ),
})

const VolvoImport = ({ onClose, setTitle }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => setTitle('Import Volvo Trucks'), [setTitle])

  const [handleTrucks, { loading }] = api.Volvo.import({
    onCompleted: onClose,
  })

  const [data] = api.Volvo.getAll()

  // Vehicle already imported if connectorAsset exists
  const vehicles = useMemo(
    () =>
      data.map((vehicle) => ({
        ...vehicle,
        key: vehicle.uniqueIdentifier,
        imported: !!vehicle.connectorAsset?.fetchActive,
      })),
    [data]
  )

  useEffect(() => {
    const selection = vehicles
      .filter(({ imported }) => imported)
      .map(({ key }) => key)

    setSelectedRowKeys(() => [...selection])
  }, [vehicles])

  const onRowClick =
    ({ key }) =>
    () =>
      setSelectedRowKeys((old) =>
        old.includes(key) ? old.filter((dk) => dk !== key) : [...old, key]
      )

  const onSaveClick = () => {
    const selected = vehicles.filter(({ key }) => selectedRowKeys.includes(key))
    const toBeAdded = selected.filter(({ imported }) => !imported)
    const toBeRemoved = vehicles.filter(
      (vehicle) => !selected.includes(vehicle) && vehicle.imported
    )

    const { data } = schema.safeParse({
      activate: toBeAdded,
      deactivate: toBeRemoved,
    })

    handleTrucks(data)
  }

  const columns = useMemo(() => [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
    },
    {
      title: 'Model',
      dataIndex: 'model',
    },
    {
      title: 'VIN',
      dataIndex: 'uniqueIdentifier',
    },
  ])

  return (
    <>
      <HR />
      <div className="grid p-8 mb-3 gap-4 grid-rows-[1fr_auto] min-h-[300px]">
        <Anttable
          size="small"
          pagination={{ position: ['bottomCenter'] }}
          columns={columns}
          dataSource={vehicles}
          className="ant-grow"
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          onRow={(vehicle) => ({
            onClick: onRowClick(vehicle),
            style: { cursor: 'pointer', userSelect: 'none' },
          })}
        />
        <Button onPress={onSaveClick} isLoading={loading}>
          Save
        </Button>
      </div>
    </>
  )
}

VolvoImport.propTypes = {
  setTitle: PropTypes.func,
  onClose: PropTypes.func,
}

export default VolvoImport
