import { requests } from 'api/client'

const api = {
  login: async (details: { email: string; password: string }) =>
    requests.post('/auth/login', details),
  getOrganization: async (token: string) => {
    const { organization } = await requests.get('/auth/me', token)
    return organization
  },
}

export default api
