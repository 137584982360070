import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  Text,
  HR,
} from '@logtrade-technology-ab/logtrade-react-components'

const ConfirmModal = ({ message, onConfirm, onCancel, onClose, ...props }) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)

  return (
    <Modal onClose={onClose} className="sm:!w-[500px]" isDismissable {...props}>
      <HR mt={2} />
      <div className="grid gap-6 p-8">
        <Text fontSize="small">{message}</Text>
        <div className="grid justify-self-end grid-cols-[repeat(2,min-content)] gap-2">
          <Button
            tint="accent"
            isLoading={confirmLoading}
            onPress={() =>
              onConfirm ? onConfirm(onClose, setConfirmLoading) : onClose()
            }
          >
            Confirm
          </Button>
          <Button
            size="sm"
            isLoading={cancelLoading}
            onPress={() =>
              onCancel ? onCancel(onClose, setCancelLoading) : onClose()
            }
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  ...Modal.propTypes,
}

export default ConfirmModal
