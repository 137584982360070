import React, { lazy } from 'react'
import {
  Button,
  useModal,
} from '@logtrade-technology-ab/logtrade-react-components'

import { Loading, CenterMessage, withSuspense } from 'components/layout'
import { UserModal } from 'components/modals'
import api from 'api'

const UsersOverview = withSuspense(lazy(() => import('./Overview')))

const NoUsersView = () => {
  const { showModal } = useModal()

  return (
    <CenterMessage
      icon="user"
      title="Add a new user"
      message="Let’s create some users! Users are essential for getting those goods
    where they need to be"
    >
      <Button onPress={() => showModal(UserModal)}>Add new user</Button>
    </CenterMessage>
  )
}

const Overview = () => {
  const [users, { loading, refetch }] = api.Organization.getUsers()
  const anyUsers = !!users.length

  if (anyUsers) return <UsersOverview users={users} refetch={refetch} />

  if (loading) return <Loading />

  return <NoUsersView />
}

export default {
  Overview,
}
