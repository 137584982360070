import React, { lazy } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { Loading, CenterMessage, withSuspense } from 'components/layout'
import api from 'api'

const ShipmentsOverview = withSuspense(lazy(() => import('./Overview')))
const ShipmentDetails = withSuspense(lazy(() => import('./Details')))

const NoShipmentsView = () => (
  <CenterMessage
    icon="archive"
    title="No shipments just yet."
    message="But as soon as they’re ready, they’ll show up here. If you think there may
      be a mistake, please contact your administrator."
  />
)

const Overview = () => {
  const [count, { loading }] = api.Shipments.getCount()

  if (count || loading) return <ShipmentsOverview totalCount={count} />

  return <NoShipmentsView />
}

const Details = () => {
  const { id } = useParams()
  const [shipment, { loading }] = api.Shipments.getById({ id })

  if (shipment) {
    return <ShipmentDetails shipment={shipment} />
  }

  if (loading) return <Loading />

  return <Navigate to=".." />
}

export default {
  Overview,
  Details,
}
