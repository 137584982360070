/* eslint-disable react/prop-types */
import React from 'react'
import { Text } from '@logtrade-technology-ab/logtrade-react-components'
import { CenterMessage } from 'components/elements'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }
  componentDidCatch(_error, _errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
  }
  render() {
    // eslint-disable-next-line no-undef
    const dev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <CenterMessage
          icon="emojiSad"
          title="Something went wrong"
          message="The app encoutered an internal error"
        >
          {dev && (
            <Text className="text-center font-code">
              {this.state.error.message}
            </Text>
          )}
        </CenterMessage>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
