import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@logtrade-technology-ab/logtrade-react-components'
import { twMerge } from 'tailwind-merge'

const AnimatedButton = ({
  onPress,
  children,
  className,
  animationClass,
  duration = 300,
  ...props
}) => {
  const [animate, setAnimate] = useState(false)

  const onButtonPress = (e) => {
    if (!animate) {
      setAnimate(true)
      setTimeout(() => setAnimate(false), duration)
    }

    onPress(e)
  }

  return (
    <Button
      onPress={onButtonPress}
      className={twMerge(className, animate && animationClass)}
      {...props}
    >
      {children}
    </Button>
  )
}

AnimatedButton.propTypes = {
  duration: PropTypes.number,
  onPress: PropTypes.func,
  animationClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

export default AnimatedButton
