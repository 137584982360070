import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Pane } from '@logtrade-technology-ab/logtrade-react-components'

// eslint-disable-next-line react/prop-types
const DetailView = ({ children, fragment, className }) => {
  const cls = twMerge('grid gap-5 _md:grid-cols-1 md:grid-cols-2', className)

  return fragment ? (
    <div className={cls}>{children}</div>
  ) : (
    <Pane>
      <div className={cls}>{children}</div>
    </Pane>
  )
}

// eslint-disable-next-line react/prop-types
const DetailViewHeader = ({ children, className }) => (
  <div className={twMerge('grid col-span-full grid-cols-1 gap-y-6', className)}>
    {children}
  </div>
)

DetailView.Header = DetailViewHeader

export default DetailView
