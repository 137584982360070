import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import {
  useLogtradeAuth,
  useModal,
} from '@logtrade-technology-ab/logtrade-react-components'
import { SetupIolModal } from 'components/modals'
import api from 'api'

const AuthGate = () => {
  const location = useLocation()
  const { isAuthenticated } = useLogtradeAuth()

  const [{ isConnectedToIol }, { loading }] = api.Organization.get()
  const { showModal } = useModal()

  useEffect(() => {
    if (isConnectedToIol === false && !loading) showModal(SetupIolModal)
  }, [isConnectedToIol, loading])

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default AuthGate
