import { graphql } from 'gql'

graphql(`
  fragment AddressFragment on ShipmentAddress {
    name
    address1
    zipCode
    city
    countryName
    contactPerson
    phoneNumber
    geoLocation {
      lat
      lng
    }
  }
`)

graphql(`
  fragment BaseVehicleFragment on Vehicle {
    id
    name
    createdAt
    assetIdentifier
    manufacturer
    model
    uniqueIdentifier
    description
    iolIdentity
    lastAddress {
      geoLocation {
        lat
        lng
      }
    }
    gateway {
      id
      manufacturer
      model
      uniqueIdentifier
      iolIdentity
    }
  }
`)

graphql(`
  fragment FullVehicleFragment on Vehicle {
    ...BaseVehicleFragment
    events {
      iolIdentity
      eventTimestamp
      properties {
        name
        value
      }
      geoLocation {
        lat
        lng
      }
      measurementsNew {
        name
        unit
        value
      }
    }
    associatedShipments {
      id
      iolIdentity
      sourceAddress {
        ...AddressFragment
      }
      destinationAddress {
        ...AddressFragment
      }
      events {
        createdAt
        eventType
        reasonText
      }
    }
  }
`)

export const GetVehiclesQuery = graphql(`
  query GetVehicles {
    vehicles {
      ...BaseVehicleFragment
    }
  }
`)

export const GetVehicleQuery = graphql(`
  query GetVehicle($id: String!) {
    vehicle(id: $id) {
      ...FullVehicleFragment
    }
  }
`)
