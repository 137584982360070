import { graphql } from 'gql'
import { z } from 'zod'

const _SaveVolvoCredschema = z.object({
  username: z.string().min(1, {
    message: 'Username is required',
  }),
  password: z.string().min(1, {
    message: 'Password is required',
  }),
})

const _ActivateVolvoschema = z.object({
  uniqueIdentifier: z.string(),
  name: z.string(),
  manufacturer: z.string(),
  model: z.string(),
})

export const SaveVolvoCredentialsMutation = graphql(`
  mutation SaveVolvoCredentials($data: SaveVolvoCredentialsInput!) {
    saveVolvoCredentials(data: $data) {
      id
    }
  }
`)

export const ActivateVolvoVehicleMutation = graphql(`
  mutation ActivateVolvoVehicle($data: ActivateRemoteVehicleInput!) {
    activateVolvoVehicle(data: $data) {
      id
    }
  }
`)

export const DeactivateVolvoVehicleMutation = graphql(`
  mutation DeactivateVolvoVehicle($data: DeactivateRemoteVehicleInput!) {
    deactivateVolvoVehicle(data: $data) {
      id
    }
  }
`)
