import React from 'react'
import { twMerge } from 'tailwind-merge'
import PropTypes from 'prop-types'
import { HR } from '@logtrade-technology-ab/logtrade-react-components'

const TableBar = ({ children, className, ...props }) => (
  <div>
    <HR />
    <div
      className={twMerge(
        'flex h-full justify-between px-2 items-center',
        className
      )}
      {...props}
    >
      {children}
    </div>
    <HR />
  </div>
)

TableBar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export default TableBar
