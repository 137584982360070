export const Role = {
  Admin: 'Admin',
}

export const EVENT_CODE_TO_TEXT = {
  1: 'Arrived',
  13: 'Picked Up',
  21: 'Delivered',
  24: 'Departed',
}

export const SHIPMENT_STATUS_TO_COLOR_STATUS = {
  Idle: 'neutral',
  Available: 'neutral',
  Reserved: 'warning',
  Transit: 'neutral',
  Delivered: 'success',
  OnHold: 'warning',
  Cancelled: 'error',
}

export const CONNECTION_STATUS_TO_COLOR_STATUS = {
  Pending: 'warning',
  Authorized: 'success',
  Denied: 'error',
  Revoked: 'danger',
}

export const TRADEUNIT_EVENT_TYPE_MAP = {
  Departed: 'Departed',
  Arrived: 'Arrived',
  PickedUp: 'Picked up',
  Delivered: 'Delivered',
  ConnectedToSensor: 'Connected to Sensor',
  DisconnectedFromSensor: 'Disconnected from Sensor',
}

export const TRADEUNIT_EVENT_TYPE_TO_ITEM_PROPS = {
  Departed: { icon: { icon: 'switch-horizontal' } },
  Arrived: { icon: { icon: 'switch-horizontal' } },
  PickedUp: {},
  Delivered: {},
  ConnectedToSensor: {},
  DisconnectedFromSensor: {},
}

export const SHIPMENT_EVENT_TYPE_MAP = {
  Assign: 'Assigned',
  Unassign: 'Unassigned',
  Pickup: 'Picked up',
  Deliver: 'Delivered',
  PutOnHold: 'Put on hold',
  Cancel: 'Cancelled',
}

export const SHIPMENT_EVENT_TYPE_TO_ITEM_PROPS = {
  Assign: {
    status: 'neutral',
    // icon: { icon: 'userAdd' },
  },
  Unassign: {
    status: 'danger',
    // icon: { icon: 'userRemove' },
  },
  Pickup: {
    status: 'neutral',
    // icon: { icon: 'locationMarker', variant: 'contained' },
  },
  Deliver: {
    status: 'success',
    // icon: { icon: 'locationMarker', variant: 'contained' },
  },
  PutOnHold: { status: 'danger' },
  Cancel: { status: 'error' },
}

export const mapbokSearchToken =
  'sk.eyJ1IjoibG9ndHJhZGUtdGVjaG5vbG9neSIsImEiOiJjbDRud2xrcWgwMXdpM3FueHNyano4aG9hIn0.TkDKtNBhMbQYla8FbDk5bA'
