import { graphql } from 'gql/gql'

graphql(`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    role
  }
`)

graphql(`
  fragment OrganizationModulesFragment on Organization {
    hasConnectedTrucks
    hasConnectedProducts
    hasConnectedHubs
  }
`)

graphql(`
  fragment OrganizationFragment on Organization {
    id
    name
    isConnectedToIol
    customerNumber
    ...OrganizationModulesFragment
    settings {
      disconnectFromSensor
      disconnectFromLocationIOLId
    }
  }
`)

export const GetOrganizationQuery = graphql(`
  query Organization {
    organization {
      ...OrganizationFragment
    }
  }
`)

export const GetUsersQuery = graphql(`
  query Users {
    organization {
      id
      users {
        ...UserFragment
      }
    }
  }
`)
