import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  HeroIcon,
  Button,
} from '@logtrade-technology-ab/logtrade-react-components'

const BackButton = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const prevPath = location.state?.from?.pathname

  return (
    <Button unstyled onPress={() => navigate(prevPath ?? '..')} {...props}>
      <HeroIcon icon="arrowLeft" className="h-6" />
    </Button>
  )
}

export default BackButton
