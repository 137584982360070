import { graphql } from 'gql'

graphql(`
  fragment ConnectionFragment on IolConnection {
    id
    createdAt
    connectionName
    status
    connectionToSelf
  }
`)

export const GetConnectionsQuery = graphql(`
  query GetConnections {
    connections {
      ...ConnectionFragment
    }
  }
`)
