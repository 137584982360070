import { FetchResult, TypedDocumentNode } from '@apollo/client'
import { useReportState } from 'features/ReportContext'
import { combine } from 'utils'
import { v4 as uuidv4 } from 'uuid'
import { MutationOptions } from './types'

export const parseResult = <T>(
  res: FetchResult<T>,
  dataKey: keyof T
): T[keyof T] => {
  if (res.data) return res.data[dataKey]

  if (res.errors) {
    throw new Error(`GQL error ${res.errors.map((e) => e.message).join(' ')}`)
  }

  throw new Error('Unknown error')
}

// Add report dispatch to onCompleted function
export const addReportOnCompleted = <
  T extends MutationOptions<TypedDocumentNode>
>(
  type: string,
  initOptions?: T
) => {
  const { addReport } = useReportState()

  // Return function which intercepts the onCompleted call
  // in options and adds a report if message provided
  return (message: string, options?: T): T => {
    // Combine the initial options for hook, the
    // mutation call options and the report dispatch
    const onCompleted = combine(
      initOptions?.onCompleted,
      options?.onCompleted,
      () => addReport({ type, message })
    )

    return { ...initOptions, ...options, onCompleted }
  }
}

export type AddReport<T extends TypedDocumentNode> = (
  message: string,
  options?: MutationOptions<T>
) => MutationOptions<T>

/**
 *
 * Api mutation decorator for report dispatching
 *
 * TS does not support supplying partial type arguments, therefore
 * we define two functions here. Ideally we'd, have one function
 * with type arguments T and R, supply T and have R inferred
 *
 * https://github.com/microsoft/TypeScript/issues/16597
 */
export const withReport =
  <T extends TypedDocumentNode>() =>
  <R>(func: (addReport: AddReport<T>) => R) => {
    const type = uuidv4()

    const callWithReport = (options?: MutationOptions<T>) =>
      func(addReportOnCompleted<MutationOptions<T>>(type, options))

    // Type is added to the wrapper so that we can access the
    // origin/id and subscribe to reports from a specific api call
    callWithReport.type = type

    return callWithReport
  }
