import React from 'react'
import PropTypes from 'prop-types'
import ApexChart from 'react-apexcharts'
import { theme } from 'utils'

const {
  colors: {
    status: { danger, success, error, warning },
  },
} = theme

const Chart = ({ options, ...props }) => (
  <ApexChart
    options={{
      colors: [success[1], warning[1], danger[1], error[1]],
      ...options,
    }}
    {...props}
  />
)

Chart.propTypes = {
  options: PropTypes.any,
}

export default Chart
