import { useMutation, useQuery } from '@apollo/client'
import { CreateConnectionMutation } from './mutations'
import { GetConnectionsQuery } from './queries'
import { withReport } from 'api/utils'
import { MutationOptions, QueryOptions } from 'api/types'
export * from './queries'
export * from './mutations'

// export function createQuery<Response, Variables = {}>(gql: DocumentNode) {

//   function hoc<P>(name: string, queryOptions?: Partial<QueryOptions<Variables>>) {
//     return graphql<Variables & P, Response, Variables>(gql, {
//       name,
//       options: variables => queryOptions,
//     });
//   }

//   return { hoc };
// }

const api = {
  // Get all connections
  getAll: (options: QueryOptions<typeof GetConnectionsQuery>) => {
    //TODO: try and sort out the as any here, there seems to be an issue with graphql
    //code generation tried updating the libs but cant seem to get it to work. see stack overflow
    //example above
    const state = useQuery(GetConnectionsQuery, options as unknown)
    const connections = state.data?.connections ?? []

    return [connections, state]
  },

  // Create new connection
  create: withReport<typeof CreateConnectionMutation>()((addReport) => {
    const [mutate, state] = useMutation(CreateConnectionMutation, {
      refetchQueries: [{ query: GetConnectionsQuery }],
    })

    const call = (
      conn: {
        connectionName: string
        partnerEmailAddress: string
      },
      options: MutationOptions<typeof CreateConnectionMutation>
    ) => {
      const data = { ...conn, iolBaseUrl: process.env.REACT_APP_IOL_API_URL }
      const opts = addReport('Connection request sent', options)

      return mutate({ ...opts, variables: { data } })
    }

    return [call, state]
  }),
}

export default api
