import { GetVolvoVehiclesQuery } from './queries'

const mockApi = {
  getAll: (loggedIn: boolean) => {
    const base = {
      request: {
        query: GetVolvoVehiclesQuery,
      },
    }

    const result = loggedIn
      ? {
          data: {
            volvoVehicles: [
              {
                uniqueIdentifier: 'whatever',
                name: 'whatever',
                manufacturer: 'whatever',
                model: 'whatever',
                connectorAsset: {
                  id: 'whatever',
                  assetId: 'whatever',
                  fetchActive: true,
                  geoLocation: {
                    lat: 0,
                    lng: 0,
                  },
                  lastTimestamp: new Date().toString(),
                },
              },
            ],
          },
        }
      : {
          errors: [{}],
        }

    return { ...base, result }
  },
}

export default mockApi
