import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Link, Text } from '@logtrade-technology-ab/logtrade-react-components'
import useAppRoutes from 'useAppRoutes'

const findAssetRoute = (routes, assetType) =>
  routes.reduce(
    (acc, cur) =>
      cur.asset === assetType
        ? cur
        : !acc && cur.children
        ? findAssetRoute(cur.children, assetType)
        : acc,
    null
  )

const generateAssetPath = (assetType, assetRoute, id, subid) => {
  if (assetType === 'productShipment') {
    return assetRoute?.path.replace(':subid', subid)
  }
  return assetRoute?.path.replace(':id', id).replace(':subid', subid)
}

const AssetLink = ({ assetType, id, subid, children, ...props }) => {
  const location = useLocation()
  const appRoutes = useAppRoutes(true)
  const assetRoute = findAssetRoute(appRoutes, assetType)
  const assetPath = generateAssetPath(assetType, assetRoute, id, subid)

  return assetRoute ? (
    <Link
      propagate={false}
      to={assetPath}
      state={{ from: location }}
      className="!text-accent-4 !text-xs"
      {...props}
    >
      {children}
    </Link>
  ) : (
    <Text {...props} className="!text-xs">
      {children}
    </Text>
  )
}

AssetLink.propTypes = {
  assetType: PropTypes.string,
  id: PropTypes.string,
  subid: PropTypes.string,
  children: PropTypes.node,
}

export default AssetLink
