import { graphql } from 'gql/gql'

graphql(`
  fragment BaseSensorFragment on Sensor {
    id
    uniqueIdentifier
    iolIdentity
    manufacturer
    model
    createdAt
    assetIdentifier
    isVirtual
    lastAddress {
      timestamp
      geoLocation {
        lat
        lng
      }
    }
    tradeUnits {
      id
      itemIdentity
    }
    batteryLevel
  }
`)

graphql(`
  fragment FullSensorFragment on Sensor {
    ...BaseSensorFragment
    events {
      iolIdentity
      eventTimestamp
      properties {
        name
        value
      }
      geoLocation {
        lat
        lng
      }
      measurementsNew {
        name
        unit
        value
      }
    }
  }
`)

export const GetSensorsQuery = graphql(`
  query GetSensors {
    sensors {
      ...BaseSensorFragment
    }
  }
`)

export const GetSensorQuery = graphql(`
  query GetSensor($id: String!) {
    sensor(id: $id) {
      ...FullSensorFragment
    }
  }
`)
