import { graphql } from 'gql'

graphql(`
  fragment GatewaysFragment on Gateway {
    id
    uniqueIdentifier
    iolIdentity
    manufacturer
    model
    createdAt
    associatedLocation {
      id
      name
    }
    associatedVehicle {
      id
      name
    }
  }
`)

export const GetGatewaysQuery = graphql(`
  query GetGateways {
    gateways {
      ...GatewaysFragment
    }
  }
`)

export const GetFreeGatewaysQuery = graphql(`
  query GetFreeGateways {
    readFreeGateways @client {
      ...GatewaysFragment
    }
  }
`)
