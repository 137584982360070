import React, { useEffect, useRef } from 'react'
import MapGL, {
  LngLatBoundsLike,
  NavigationControl,
  PaddingOptions,
  PointLike,
  ViewState,
} from 'react-map-gl'
import { PickingInfo } from '@deck.gl/core'
import DeckGLOverlay from './DeckGLOverlay'
import { LayersList } from 'deck.gl'
import { GeoLocation } from './layers/ILayer'

export const mapboxPkToken =
  'pk.eyJ1IjoibG9ndHJhZGUtdGVjaG5vbG9neSIsImEiOiJjbDRtazFzbjQwenJnM21vMWhhOGl2Z2t6In0.uEhyuDNVp0UuaL40dswfPg'

export interface DeckGlMapProps {
  children?: React.ReactNode
  width: string | number
  height: string | number
  highlight?: GeoLocation
  layers?: LayersList
  sensorFilter?: JSX.Element
  timeRangeSelector?: JSX.Element
  initialViewState?: Partial<ViewState> & {
    bounds?: LngLatBoundsLike
    fitBoundsOptions?: {
      offset?: PointLike
      minZoom?: number
      maxZoom?: number
      padding?: number | PaddingOptions
    }
  }
}

function getTooltipContent(object): string | undefined {
  if (!object) return undefined
  switch (object.__typename) {
    case 'Location':
      return `${object.name}\n${object.address1}\n${object.zipCode} ${object.city}`
    case 'AssetEvent': {
      const formattedTimestamp = new Date(object.timestamp).toLocaleString(
        'sv-SE',
        {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }
      )
      return `${object.sensorLabel}\n${formattedTimestamp}`
    }
    case 'Vehicle':
      return `${object.name}\n${object.uniqueIdentifier}`
    case 'Sensor':
      return `${object.manufacturer}\n${object.model}\n${object.uniqueIdentifier}`
    case 'TradeUnit':
      return `Item: ${object.itemIdentity}\nDescription: ${object.description}\nSerial number: ${object.serialNumber}`
    default:
      return undefined
  }
}

const DeckGlMap = React.forwardRef<HTMLDivElement, DeckGlMapProps>(
  (props, ref) => {
    const {
      width = '100%',
      height = '100%',
      highlight,
      layers,
      sensorFilter,
      timeRangeSelector,
      initialViewState,
      ...otherProps
    } = props
    const mapRef = useRef(null)

    useEffect(() => {
      if (highlight && mapRef.current) {
        mapRef.current.getMap().flyTo({
          center: [highlight.lng, highlight.lat],
          zoom: 12,
        })
      }
    }, [highlight])

    return (
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <MapGL
          ref={mapRef}
          initialViewState={initialViewState}
          style={{ width, height }}
          mapStyle="mapbox://styles/mapbox/light-v9"
          mapboxAccessToken={mapboxPkToken}
          scrollZoom={true}
          {...otherProps}
        >
          <DeckGLOverlay
            layers={layers}
            controller={true}
            getTooltip={({ object }: PickingInfo) => getTooltipContent(object)}
          />
          <NavigationControl position="top-right" />
          <div className="absolute top-4 left-4 flex gap-4">
            {sensorFilter && sensorFilter}
          </div>
        </MapGL>
        {timeRangeSelector && timeRangeSelector}
      </div>
    )
  }
)

export default DeckGlMap
