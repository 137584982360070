import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { EventTimeline } from 'components/elements'
import { dateAndTime } from 'utils'
import { twMerge } from 'tailwind-merge'

const styles = {
  base: 'border border-transparent py-1 my-1 rounded !duration-[50ms]',
  hover:
    'hover:border-main-5 hover:-ml-1 hover:pl-1 cursor-pointer transition-all select-none',
  select: '!border-accent-5 -ml-1 pl-1',
}

const defaultBody = ({ geoLocation }) =>
  geoLocation ? `${geoLocation.lat}, ${geoLocation.lng}` : '-'

const InteractiveTimeline = ({
  events,
  getTitle,
  getText,
  onChange,
  onClick,
  ...props
}) => {
  const [selected, setSelected] = useState(null)

  const isSelected = useCallback(
    (event) => event && selected && event.iolIdentity === selected.iolIdentity,
    [selected]
  )

  const select = (event) => {
    onClick?.(event)

    if (onChange) {
      const e = isSelected(event) ? null : event
      setSelected(e)
      onChange(e)
    }
  }

  const _getText = getText ?? defaultBody
  const _getTitle = getTitle ? getTitle : () => 'Event'

  const baseItems = useMemo(
    () =>
      events.map((event) => ({
        title: _getTitle(event),
        text: _getText(event),
        stamp: dateAndTime(event.eventTimestamp),
        status: 'neutral',
        icon: event.inferred ? { icon: 'Link' } : undefined,
        className: twMerge(styles.base, (onChange || onClick) && styles.hover),
        event,
      })),
    [events]
  )

  const fullItems = useMemo(
    () =>
      baseItems.map(({ event, className, ...rest }) => ({
        ...rest,
        onClick: () => select(event),
        className: twMerge(className, isSelected(event) && styles.select),
      })),
    [baseItems, selected]
  )

  return <EventTimeline {...props} items={fullItems} />
}

InteractiveTimeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  title: PropTypes.string,
  getText: PropTypes.func,
  getTitle: PropTypes.func,
  onClick: PropTypes.func,
}

export default InteractiveTimeline
