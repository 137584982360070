import { graphql } from 'gql/gql'

graphql(`
  fragment ProfileFragment on User {
    id
    firstName
    lastName
    email
    role
  }
`)

/**
 * Get self (user)
 */
export const GetMeQuery = graphql(`
  query GetMe {
    me {
      ...ProfileFragment
    }
  }
`)
