import React, { createContext, useState, useContext, useEffect } from 'react'

const PageContext = createContext({
  state: {},
  setState: () => null,
  clearState: () => null,
})

// eslint-disable-next-line react/prop-types
export const PageProvider = ({ children }) => {
  const [state, setState] = useState({})

  const clearState = () => setState({})

  return (
    <PageContext.Provider value={{ state, setState, clearState }}>
      {children}
    </PageContext.Provider>
  )
}

export const usePageContext = () => useContext(PageContext)

// Bind a stateful variable to page context
export const useBoundState = (name, v) => {
  const { state, setState } = useContext(PageContext)
  const [value, setValue] = useState(state[name] ?? v)

  useEffect(() => {
    setState((o) => ({ ...o, [name]: value }))
  }, [])

  const setLocalAndState = (vorf) =>
    setValue((o) => {
      const newValue = typeof vorf === 'function' ? vorf(o) : vorf
      setState((o) => ({ ...o, [name]: newValue }))
      return newValue
    })

  return [value, setLocalAndState]
}

export const PageConsumer = PageContext.Consumer
