import { graphql } from 'gql/gql'

graphql(`
  fragment ShipmentAddressFragment on ShipmentAddress {
    name
    address1
    zipCode
    city
    countryName
    contactPerson
    phoneNumber
    geoLocation {
      lat
      lng
    }
    iolIdentity
  }
`)

graphql(`
  fragment ShipmentFragment on Shipment {
    id
    iolIdentity
    shipmentNumber
    shipmentDate
    carrierName
    carrierCode
    status
    sourceAddress {
      ...ShipmentAddressFragment
    }
    destinationAddress {
      ...ShipmentAddressFragment
    }
    packageItems {
      id
      grossWeight
    }
    events {
      createdAt
      eventType
      reasonText
    }
    transportStatuses {
      createdAt
      iolIdentity
      transportInstructionIolIdentity
      eventTimestamp
      geoLocation {
        lat
        lng
      }
      eventCode
      eventText
      reasonCode
      reasonText
    }
  }
`)

export const GetShipmentQuery = graphql(`
  query GetShipment($id: String!) {
    shipment(id: $id) {
      ...ShipmentFragment
    }
  }
`)

export const GetShipmentsQuery = graphql(`
  query GetShipments(
    $filter: ShipmentFilterInput
    $pagination: ShipmentPaginationInput
    $sorting: ShipmentSortingInput
  ) {
    shipments(filter: $filter, pagination: $pagination, sorting: $sorting) {
      ...ShipmentFragment
    }
  }
`)

export const GetShipmentCountQuery = graphql(`
  query GetShipmentCount($filter: ShipmentFilterInput) {
    shipmentCount(filter: $filter)
  }
`)

export const GetAssociatedShipmentsQuery = graphql(`
  query TradeUnitShipmentAssociations($tradeUnitId: String!) {
    tradeUnitShipmentAssociations(iolIdentity: $tradeUnitId) {
      ...ShipmentFragment
    }
  }
`)
