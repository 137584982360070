import {
  Badge,
  Button,
  Checkbox,
  Select,
  HR,
  HeroIcon,
  Snackbar,
  Modal,
  Radio,
  RadioGroup,
  Text,
  TextField,
} from '@logtrade-technology-ab/logtrade-react-components'
import api from 'api'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const Adder = ({ items, setItems, setError }) => {
  const [iolCloud, setIolCloud] = useState('https://iol.logtrade.org')
  const [iolOrgId, setIolOrgId] = useState('')
  const textFieldRef = useRef(null)

  const onAdd = (e) => {
    e.preventDefault()
    textFieldRef?.current.focus()

    setError(undefined)

    const cloud = iolCloud.trim()
    if (!cloud) {
      setError('IOL cloud must be given (e.g. https://iol.logtrade.org)')
      return
    }
    try {
      const cloudURL = new URL(cloud)
      if (cloudURL.protocol !== 'http:' && cloudURL.protocol !== 'https:') {
        setError(
          `IOL cloud "${cloud}" does not start with "http" or "https" which is required`
        )
        return
      } else if (cloud !== cloudURL.origin) {
        setError(
          `IOL cloud "${cloud}" contains more than just the first part of the URL${
            cloudURL.origin ? ` (did you mean "${cloudURL.origin}"?)` : ''
          }`
        )
        return
      }
    } catch (e) {
      setError(`"${cloud}" is not a valid URL`)
      return
    }

    const orgId = iolOrgId.trim()
    if (orgId.length === 0) {
      setError(
        'IOL Organization ID must be given (it can be found on the start page of IOL)'
      )
      return
    }
    if (orgId.includes('/')) {
      setError('The IOL Organization ID may not contain slashes')
      return
    }

    const iolId = `${cloud}/${orgId}`
    if (items.includes(iolId)) return

    setItems((is) => [...is, iolId.trim()])
    setIolOrgId('')
  }

  const onRemove = (item) => () =>
    setItems((is) => is.filter((i) => i !== item))

  return (
    <form
      onClick={(e) => e.stopPropagation()}
      onSubmit={onAdd}
      className="grid grid-rows gap-2 transition-all"
    >
      <div className="grid grid-cols-[2fr,3fr,auto] gap-2 transition-all">
        <TextField
          placeholder="IOL Cloud"
          value={iolCloud}
          onChange={setIolCloud}
          ref={textFieldRef}
        />
        <TextField
          placeholder="IOL Organization ID"
          value={iolOrgId}
          onChange={setIolOrgId}
          ref={textFieldRef}
        />
        <Button size="xs" type="submit">
          &nbsp;Add&nbsp;
        </Button>
      </div>
      <hr />
      {!!items.length && (
        <div className="flex flex-wrap gap-1">
          {items.map((item, i) => (
            <Badge
              key={i}
              className="min-w-min border bg-white px-2 flex items-center gap-2"
            >
              {item}
              <Button unstyled onPress={onRemove(item)}>
                <HeroIcon icon="x" className="h-3" />
              </Button>
            </Badge>
          ))}
        </div>
      )}
    </form>
  )
}

Adder.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func,
  setError: PropTypes.func,
}

// eslint-disable-next-line react/prop-types
const OptionCheckbox = ({ label, children, className, ...props }) => (
  <Checkbox
    className={twMerge(
      className,
      'grid grid-cols-[auto,1fr] items-center gap-2'
    )}
    {...props}
  >
    <div className="flex items-center">{label}</div>
    <span />
    <div>{children}</div>
  </Checkbox>
)
/*
      Presence sensor format { iolIdentity, name }
*/

const LockAccessModal = ({ lock, gateways, onClose, ...props }) => {
  const [operationMode, setOperationMode] = useState(
    lock.accessPolicy
      ? lock.accessPolicy.operationMode ??
          (lock.accessPolicy.enabled ? 'Test' : 'Off')
      : 'Off'
  )
  const [carrierWhitelist, setCarrierWhitelist] = useState(
    lock.accessPolicy?.carrierWhitelist ?? []
  )
  const [carrierWhitelistIgnored, setCarrierWhitelistIgnored] = useState(
    lock.accessPolicy?.carrierWhitelistIgnored ?? false
  )
  const [driverAction, setDriverAction] = useState(
    lock.accessPolicy?.driverAction ?? false
  )
  const [activeShipment, setActiveShipment] = useState(
    lock.accessPolicy?.activeShipment ?? false
  )
  const [shipmentSourceOrDestination, setShipmentSourceOrDestination] =
    useState(lock.accessPolicy?.shipmentSourceOrDestination ?? false)

  const [tradeUnitMaxDistance, setTradeUnitMaxDistance] = useState(
    lock.accessPolicy?.tradeUnitMaxDistance ?? null
  )
  const [tradeUnitMaxDistanceIgnored, setTradeUnitMaxDistanceIgnored] =
    useState(lock.accessPolicy?.tradeUnitMaxDistanceIgnored ?? false)

  const [tradeUnitPresenceDetectedBy, setTradeUnitPresenceDetectedBy] =
    useState(lock.accessPolicy?.tradeUnitPresenceDetectedBy ?? null)

  const [tradeUnitPresenceIgnored, setTradeUnitPresenceIgnored] = useState(
    lock.accessPolicy?.tradeUnitPresenceIgnored ?? false
  )

  const [error, setError] = useState(undefined)

  const presenceSensors = api.Locations.associatedGateways()

  const [savePolicy, { loading: isSavingPolicy }] =
    api.Locations.saveLockAccessPolicy({
      onError: ({ message }) =>
        setError(`Could not save policy. Error: ${message}`),
      onCompleted: onClose,
    })

  const onSave = () => {
    setError(undefined)
    savePolicy({
      lockIolId: lock.iolIdentity,
      policy: {
        operationMode,
        carrierWhitelist,
        carrierWhitelistIgnored,
        driverAction,
        activeShipment,
        shipmentSourceOrDestination,
        tradeUnitMaxDistance,
        tradeUnitMaxDistanceIgnored,
        tradeUnitPresenceDetectedBy,
        tradeUnitPresenceIgnored,
      },
    })
  }

  return (
    <Modal
      title={`Settings for ${lock.assetIdentifier}`}
      onClose={onClose}
      isDismissable
      className="md:max-h-[90vh] overflow-y-auto"
      {...props}
    >
      <HR />
      <div autoComplete="off" className="grid gap-8 p-8 !pb-10">
        {error && (
          <Snackbar stack={false} status="error">
            {error}
          </Snackbar>
        )}
        <div className="grid auto-rows-min gap-7 bg-main-9 min-h-[300px] rounded-lg p-5">
          <div>
            <Text tag="h6">Operation Mode</Text>
            <br />
            <RadioGroup
              orientation="vertical"
              className="text-s"
              value={operationMode}
              onChange={(v) => setOperationMode(v)}
            >
              <Radio value="Live">
                <span className="font-bold">Live</span> &nbsp; - lock may open
              </Radio>
              <Radio value="Test">
                <span className="font-bold">Test</span> &nbsp; - lock will not
                open (but attempts can be made and will be logged)
              </Radio>
              <Radio value="Off">
                <span className="font-bold">Off</span> &nbsp; - lock will not
                open (and attempts will be blocked)
              </Radio>
            </RadioGroup>
          </div>
          <Text tag="h6">Access Policy</Text>
          <OptionCheckbox
            label="Allow these carrier organizations to open lock for delivery"
            isSelected={!carrierWhitelistIgnored}
            onChange={(checked) => setCarrierWhitelistIgnored(!checked)}
          >
            <Adder
              items={carrierWhitelist}
              setItems={setCarrierWhitelist}
              setError={setError}
            />
          </OptionCheckbox>
          <OptionCheckbox
            label="Require a Tradeunit near lock to be in transit (on active shipment)"
            isSelected={activeShipment}
            onChange={setActiveShipment}
          >
            <RadioGroup
              orientation="vertical"
              className="text-xs"
              value={shipmentSourceOrDestination ? '1' : '2'}
              onChange={(v) => setShipmentSourceOrDestination(v === '1')}
            >
              <Radio value="1">
                Allow open only if this lock is at the pickup or delivery
                location
              </Radio>
              <Radio value="2">
                Allow open if this lock is at another location (e.g midway or
                crossdock)
              </Radio>
            </RadioGroup>
          </OptionCheckbox>
          <OptionCheckbox
            label="Require driver to use lock app"
            isSelected={driverAction}
            onChange={setDriverAction}
          >
            <Text tag="span" className="text-xs text-status-danger-1">
              This lock will auto-open if the below conditions are true
            </Text>
          </OptionCheckbox>
          <OptionCheckbox
            label={
              <>
                Require Tradeunit to be within
                <TextField
                  defaultValue={10}
                  type="number"
                  className="w-16 mx-2 h-7"
                  value={tradeUnitMaxDistance}
                  onChange={(v) => setTradeUnitMaxDistance(parseFloat(v))}
                />
                meters
              </>
            }
            isSelected={!tradeUnitMaxDistanceIgnored}
            onChange={(checked) => setTradeUnitMaxDistanceIgnored(!checked)}
          />
          <OptionCheckbox
            label="Require Tradeunit to be near presence sensor"
            isSelected={!tradeUnitPresenceIgnored}
            onChange={(checked) => setTradeUnitPresenceIgnored(!checked)}
          >
            <Select
              placeholder={
                presenceSensors?.length
                  ? 'Select presence sensor...'
                  : 'No presence sensors available'
              }
              size="sm"
              buttonProps={{
                tint: 'light',
                className: 'mx-2',
              }}
              value={tradeUnitPresenceDetectedBy}
              onSelectionChange={(v) => {
                setTradeUnitPresenceDetectedBy(v)

                // the following line is a hack to fix a weird bug where the checkbox was mysteriously flipped
                setTradeUnitPresenceIgnored(!tradeUnitPresenceIgnored)
              }}
            >
              {gateways.map((g) => (
                <Select.Item key={g.iolIdentity} value={g.iolIdentity}>
                  {g.uniqueIdentifier}
                </Select.Item>
              ))}
            </Select>
          </OptionCheckbox>
        </div>
        <div className="flex justify-end gap-2">
          <Button
            type="submit"
            tint="accent"
            isLoading={isSavingPolicy}
            isDisabled={!lock.accessPolicy}
            onPress={onSave}
            className="align-self-end"
          >
            Save
          </Button>
          <Button onPress={onClose} className="align-self-end">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

LockAccessModal.propTypes = {
  user: PropTypes.object,
  ...Modal.propTypes,
}

export default LockAccessModal
