/* eslint-disable @typescript-eslint/no-explicit-any */
import superagent, { Request, Response } from 'superagent'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import { setContext } from '@apollo/client/link/context'
import policies from './policies'

let token = ''

export const setToken = (_token: string) => {
  token = _token
}

const getAPIHost = () => process.env.REACT_APP_CG_API_URL

const API_ROOT = (version: string | number) => `${getAPIHost()}/api/${version}`
const GQL_ROOT = () => `${getAPIHost()}/graphql`

const getEndpoint = (path: string, version = 1) => `${API_ROOT(version)}${path}`
const responseBody = (res: Response) => res.body

const httpLink = createHttpLink({
  uri: GQL_ROOT,
})

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: token ? `Bearer ${token}` : '',
  },
}))

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

// Initialize apollo client with persistent cache
const cache = new InMemoryCache({
  typePolicies: policies,
  resultCacheMaxSize: 52428800,
})
const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
  debug: false,
})

export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'none',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'none',
    },
  },
  cache,
})

// Init must be called before using apolloClient,
// preferrably before any api call dispatches
export const restore = async () => {
  await persistor.restore()
  return apolloClient
}

// Call purge on logout to remove any cached data
export const purge = () => {
  setToken('')
  apolloClient.clearStore()
  persistor.purge()
}

const addToken = (passedToken?: string) => (req: Request) => {
  if (token || passedToken)
    req.set('Authorization', `Bearer ${passedToken || token}`)
}

export const requests = {
  del: (path: string, passedToken?: string) =>
    superagent
      .del(getEndpoint(path))
      .use(addToken(passedToken))
      .then(responseBody),
  get: (path: string, passedToken?: string) =>
    superagent
      .get(getEndpoint(path))
      .use(addToken(passedToken))
      .then(responseBody),
  put: (path: string, body: any, passedToken?: string) =>
    superagent
      .put(getEndpoint(path), body)
      .use(addToken(passedToken))
      .then(responseBody),
  postRaw: (path: string, body: any, passedToken?: string) =>
    superagent
      .post(getEndpoint(path), body)
      .accept('json')
      .use(addToken(passedToken)),
  post: (path: string, body: any, passedToken?: string) =>
    requests.postRaw(path, body, passedToken).then(responseBody),
}
