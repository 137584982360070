import React from 'react'
import { Outlet } from 'react-router-dom'
import {
  Navbar,
  HeroIcon,
  Text,
  AppWrap,
} from '@logtrade-technology-ab/logtrade-react-components'
import SideMenu from './SideMenu'
import { useActiveRoutes } from 'features/hooks'
import ErrorBoundary from 'features/ErrorBoundary'
import api from 'api'

const Layout = () => {
  const [profile] = api.Profile.get()

  // Use the root active route to determine title and links
  const { root } = useActiveRoutes()

  return (
    <AppWrap className="flex flex-col bg-main-8 pt-16">
      <Navbar
        title={root.name ?? 'Connected Goods'}
        links={root.children}
        sideMenu={<SideMenu />}
        user={
          <div className="flex items-center">
            <Text className="text-xs truncate">{profile.email}</Text>
            <HeroIcon icon="userCircle" variant="solid" className="w-8 ml-2" />
          </div>
        }
      />
      <ErrorBoundary>
        <div className="grid grow relative p-6">
          <Outlet />
        </div>
      </ErrorBoundary>
    </AppWrap>
  )
}

export default Layout
