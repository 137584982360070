import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Text,
  HR,
  Button,
} from '@logtrade-technology-ab/logtrade-react-components'
import ManualTruck from './Manual'
import { VolvoImport, VolvoLogin } from './Volvo'
import api from 'api'

const TruckModal = ({ truck, onClose, ...props }) => {
  const [choice, setChoice] = useState(null)
  const [title, setTitle] = useState('Choose how to continue')

  useEffect(() => {
    if (truck)
      setChoice(
        <ManualTruck onClose={onClose} truck={truck} setTitle={setTitle} />
      )
  }, [truck, setChoice, onClose])

  const [volvoFetched, setVolvoFetched] = useState(false)
  const [volvoChosen, setVolvoChosen] = useState(false)

  const [_, { error: authRequired }] = api.Volvo.getAll({
    onError: () => setVolvoFetched(true),
    onCompleted: () => setVolvoFetched(true),
  })

  useEffect(() => {
    if (volvoFetched && volvoChosen)
      setChoice(
        authRequired ? (
          <VolvoLogin onClose={onClose} setTitle={setTitle} />
        ) : (
          <VolvoImport onClose={onClose} setTitle={setTitle} />
        )
      )
  }, [volvoFetched, volvoChosen])

  return (
    <Modal title={title} onClose={onClose} isDismissable {...props}>
      {choice ?? (
        <>
          <HR />
          <div className="flex mb-3 justify-center items-center min-h-[300px]">
            <div className="grid justify-items-center items-center gap-5">
              <Button
                size="sm"
                className="w-[200px]"
                isLoading={!volvoFetched && volvoChosen}
                onPress={() => setVolvoChosen(true)}
              >
                Connect to Volvo
              </Button>
              <Text
                onClick={() =>
                  setChoice(
                    <ManualTruck onClose={onClose} setTitle={setTitle} />
                  )
                }
                className="underline cursor-pointer"
              >
                Add truck manually
              </Text>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

TruckModal.propTypes = {
  truck: PropTypes.object,
  ...Modal.propTypes,
}

export default TruckModal
