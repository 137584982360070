import { useMemo } from 'react'

const isMatch = (value, query) => {
  if (value == null || query == null) return false

  const normalizedValue =
    typeof value === 'string' ? value : JSON.stringify(value)
  const normalizedQuery = query.toLowerCase()

  return normalizedValue.toLowerCase().includes(normalizedQuery)
}

/**
 * Filters data based on search query
 * @param query The search query (string)
 * @param mapper Mapper (object):
 * 		{ properyName: (val) => string }
 *
 * @returns filtered array
 */
const useSearchFilter = (query, mapper = {}) => {
  return useMemo(() => {
    return (data) => {
      return data.filter((dp) =>
        Object.keys(mapper).reduce(
          (acc, key) =>
            isMatch(
              mapper[key] ? mapper[key](dp[key]) : dp[key],
              query.toLowerCase()
            ) || acc,
          false
        )
      )
    }
  }, [query, mapper])
}

export default useSearchFilter
