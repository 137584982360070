import React, { Suspense } from 'react'
import { Loading } from '.'

// eslint-disable-next-line react/display-name
const withSuspense = (Component) => (props) =>
  (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  )

export default withSuspense
