import { FieldPolicy } from '@apollo/client'
import { Gateway } from 'gql/graphql'

const policies: Record<string, FieldPolicy> = {
  // Read non-associated gateways
  readFreeGateways: {
    read(_, { readField }) {
      const gateways = readField<Partial<Gateway>[]>('gateways')

      return (gateways ?? []).filter((gatewayRef) => {
        const location = readField('associatedLocation', gatewayRef)
        const vehicle = readField('associatedVehicle', gatewayRef)
        return !location && !vehicle
      })
    },
  },
}

export default policies
