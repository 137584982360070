import _ from 'lodash'

type Func<T> = (data: T) => unknown

type AnyRecord = Record<string | number | symbol, unknown>

// Chain multiple functions
export const chain =
  <T>(...funcs: Func<T>[]) =>
  (data: T) =>
    funcs.reduce((acc, curr) => curr(acc), data)

// Omit field(s) of object
export const omit = <T extends AnyRecord>(
  object: T,
  field: keyof T | Array<keyof T>
) => {
  const fields = _.castArray(field)

  return Object.fromEntries(
    Object.entries(object).filter(([key]) => !fields.includes(key))
  )
}

// Spread which keeps order
export const updateObject = <T extends AnyRecord>(object: T, update: T) =>
  Object.keys(object).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: curr in update ? update[curr] : object[curr],
    }),
    {}
  )

// Initialize object props to value with keys from target
export const initKeys = <T extends keyof AnyRecord>(
  target: Array<T>,
  value = null
) =>
  target.reduce((acc, curr) => ({ ...acc, [curr]: value }), {}) as Record<
    T,
    typeof value
  >

// Keep only keys with truthy values (basically array.filter(Boolean) for objects)
export const keyp = <T extends AnyRecord>(source: T) => {
  const target = {}

  Object.entries(source).forEach(([key, value]) => {
    if (value) target[key] = value
  })

  return target as Partial<T>
}

// Run multiple functions by calling one
export const combine =
  <A extends Array<unknown>, R = Array<unknown>>(
    ...funcs: Array<(...args: A) => R>
  ) =>
  (...args: A) =>
    funcs.map((f) => f?.(...args))
