import React, { lazy } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import {
  useModal,
  Button,
} from '@logtrade-technology-ab/logtrade-react-components'

import { Loading, CenterMessage, withSuspense } from 'components/layout'
import { TruckModal } from 'components/modals'
import api from 'api'

const TrucksOverview = withSuspense(lazy(() => import('./Overview')))
const TruckDetails = withSuspense(lazy(() => import('./Details')))

const NoTrucksView = () => {
  const { showModal } = useModal()

  return (
    <CenterMessage
      icon="truck"
      title="Add a new truck"
      message="Let’s create a fleet! Trucks are essential movers in the IoL."
    >
      <Button onPress={() => showModal(TruckModal)}>Add new truck</Button>
    </CenterMessage>
  )
}

const Overview = () => {
  const [trucks, { loading, refetch }] = api.Vehicles.getAll()
  const anyTrucks = !!trucks.length

  if (anyTrucks) return <TrucksOverview trucks={trucks} refetch={refetch} />

  if (loading) return <Loading />

  return <NoTrucksView />
}

const Details = () => {
  const { id } = useParams()
  const [truck, { loading }] = api.Vehicles.getById({ id })

  if (truck) return <TruckDetails truck={truck} />

  if (loading) return <Loading />

  return <Navigate to=".." />
}

export default {
  Overview,
  Details,
}
