import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { LogtradeAuthProvider } from '@logtrade-technology-ab/logtrade-react-components'
import App from './App'
import api from 'api'

import './index.css'
import '@logtrade-technology-ab/logtrade-react-components/lib.css'

const container = document.getElementById('root')
const root = createRoot(container)

api.restore().then((client) =>
  root.render(
    <ApolloProvider client={client}>
      <LogtradeAuthProvider>
        <Router>
          <App />
        </Router>
      </LogtradeAuthProvider>
    </ApolloProvider>
  )
)
