import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'
import { Marker } from 'react-map-gl'
import { useMapboxAutofill } from '@mapbox/search-js-react'
import {
  ComboBox,
  HeroIcon,
  Button,
} from '@logtrade-technology-ab/logtrade-react-components'
import { BaseMap } from 'components/modules'
import { mapbokSearchToken, convertPoint } from 'utils'

const defaultView = {
  zoom: 9,
  pitch: 30,
}

const suggestConfig = {
  limit: 5,
  sessionToken: mapbokSearchToken,
}

const containerStyle = (isDisabled) =>
  twMerge(
    'relative bg-main-8 rounded overflow-hidden',
    isDisabled &&
      'after:absolute after:inset-0 after:[content:""] after:bg-main-9/75 after:z-10'
  )

const AddressChoice = React.forwardRef(
  ({ onChange, onBlur, startLocation, disabled, ...props }, ref) => {
    const mapRef = useRef(null)
    const [location, setLocation] = useState(startLocation ?? null)
    const [suggestions, setSuggestions] = useState([])
    const initialView = { ...defaultView, ...startLocation }

    const transition = useCallback((ptOjb) => {
      setLocation(ptOjb)
      mapRef.current?.flyTo({
        center: convertPoint(ptOjb),
        duration: 1000,
        zoom: 16,
      })
    }, [])

    const session = useMapboxAutofill({ accessToken: mapbokSearchToken })

    const updateSuggestions = (text) =>
      text &&
      session
        .suggest(text, suggestConfig)
        .then(({ suggestions }) => setSuggestions(suggestions))

    const selectSugggestion = (id) => {
      const address = suggestions.find((s) => s.id === id)

      if (address) {
        session.canRetrieve(address) &&
          session.retrieve(address, suggestConfig).then(({ features }) => {
            const coords = convertPoint(features[0].geometry.coordinates)
            onChange({ ...address, coords })
            transition(coords)
          })
      } else {
        setLocation(null)
        onChange(null)
      }
    }

    const reverseGeocode = (coords) => {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords.longitude},${coords.latitude}.json?access_token=${mapbokSearchToken}`

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const addressFeature = data.features.find((feature) =>
            feature.place_type.includes('address')
          )
          const postcodeFeature = data.features.find((feature) =>
            feature.place_type.includes('postcode')
          )
          const countryCodeFeature = data.features.find((feature) =>
            feature.place_type.includes('country')
          )
          const regionFeature = data.features.find((feature) =>
            feature.place_type.includes('region')
          )
          const placeFeature = data.features.find((feature) =>
            feature.place_type.includes('place')
          )

          onChange({
            coords: coords,
            place: placeFeature.text,
            address_line1: addressFeature.text,
            address_level1: regionFeature.text,
            address_levet2: placeFeature.text,
            postcode: postcodeFeature.text,
            country_code: countryCodeFeature.properties.short_code,
            region: regionFeature.text,
            description: placeFeature.text + ', ' + countryCodeFeature.text,
          })
          transition(coords)
          updateSuggestions(addressFeature.text + ', ' + postcodeFeature.text)
        })
        .catch((error) => console.error(error))
    }

    const setCurrentLocation = () =>
      'geolocation' in navigator &&
      navigator.geolocation.getCurrentPosition(
        ({ coords: { longitude, latitude } }) =>
          transition({ longitude, latitude })
      )

    const onMarkerDragEnd = useCallback((event) => {
      const coords = {
        longitude: event?.lngLat?.lng,
        latitude: event?.lngLat?.lat,
      }
      if (coords.latitude && coords.longitude) {
        reverseGeocode(coords)
      } else {
        console.error('coords is undefined')
      }
    }, [])

    return (
      <div
        className={containerStyle(disabled)}
        aria-disabled={disabled}
        {...props}
      >
        <BaseMap
          ref={mapRef}
          initialView={initialView}
          width="100%"
          height={350}
        >
          {location && (
            <Marker
              {...location}
              anchor="bottom"
              draggable
              onDragEnd={onMarkerDragEnd}
            >
              <HeroIcon
                height="25px"
                icon="locationMarker"
                variant="solid"
                className="fill-status-success-1"
              />
            </Marker>
          )}
          <div className="grid grid-cols-2 items-center p-3">
            <ComboBox
              ref={ref}
              autoFocus
              aria-label="address"
              placeholder="Search address"
              items={suggestions}
              allowsEmptyCollection={true}
              onSelectionChange={selectSugggestion}
              onInputChange={updateSuggestions}
              shouldCloseOnBlur={true}
              shouldFilter={false}
              onBlur={onBlur}
              className="w-full"
              isDisabled={disabled}
            >
              {(suggestion) => (
                <ComboBox.Item>{suggestion.place_name}</ComboBox.Item>
              )}
            </ComboBox>
            <Button
              zIndex={10}
              onPress={setCurrentLocation}
              tint="light"
              size="sm"
              className="justify-self-end"
            >
              Current location
            </Button>
          </div>
        </BaseMap>
      </div>
    )
  }
)

AddressChoice.displayName = 'AddressChoice'

AddressChoice.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  startLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  disabled: PropTypes.bool,
}

export default AddressChoice
