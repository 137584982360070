/* eslint-disable react/prop-types */
import React from 'react'
import {
  PropertyRow,
  HeroIcon,
  Text,
  Button,
} from '@logtrade-technology-ab/logtrade-react-components'
import api from 'api'
import useAppRoutes from 'useAppRoutes'
import { Link } from 'react-router-dom'
import { capitalize, theme } from 'utils'
import { twMerge } from 'tailwind-merge'

const {
  colors: {
    status: { success, error, neutral },
  },
} = theme

// eslint-disable-next-line no-undef
const env = capitalize(process.env.REACT_APP_MODE)

const Dot = ({ className, ...props }) => (
  <div className={twMerge('w-2 h-2 rounded-full', className)} {...props} />
)

const StatusValue = ({ text, color }) => (
  <div className="flex items-center gap-1">
    {text}
    <Dot style={{ background: color }} />
  </div>
)

const LandingPage = () => {
  const [profile] = api.Profile.get()
  const routes = useAppRoutes().filter(({ path }) => !['/', '*'].includes(path))

  const [{ isConnectedToIol }, { loading }] = api.Organization.get()

  const properties = [
    {
      label: 'IOL Connection',
      value: (
        <StatusValue
          text={isConnectedToIol ? 'Active' : loading ? 'Loading' : 'Inactive'}
          color={
            isConnectedToIol ? success[1] : loading ? neutral[1] : error[1]
          }
        />
      ),
    },
    { label: 'Environment', value: env },
  ]

  return (
    <div className="p-4 xl:!p-12 flex flex-col items-center gap-16">
      <Text tag="h3" className="text-center">
        Welcome to <b>Connected Goods</b> {profile.firstName}
      </Text>
      <PropertyRow className="w-full max-w-7xl" data={properties} />
      <div className="w-full grid gap-10 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl">
        {routes.map(({ children, name: moduleName, path: modulePath }) => (
          <div key={moduleName} className="flex flex-col gap-4">
            <Text tag="h6">{moduleName}</Text>
            {children.map(({ name, path, icon }) => (
              <Link to={[modulePath, path].join('/')} key={name}>
                <Button
                  size="lg"
                  tint="light"
                  className="w-full hover:scale-[1.01] transition-transform duration-75 rounded-md"
                >
                  <div className="flex flex-row justify-between">
                    {name}
                    {icon && <HeroIcon icon={icon} className="w-5" />}
                  </div>
                </Button>
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default LandingPage
