import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@logtrade-technology-ab/logtrade-react-components'

const GlobalFormError = ({ error, ...props }) => (
  <div {...props}>
    {error?.message && (
      <Text
        key={error?.message}
        className="text-xs !text-status-error-1 text-center"
      >
        {error?.message}
      </Text>
    )}
  </div>
)

GlobalFormError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
}

export default GlobalFormError
