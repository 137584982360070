import React, { lazy } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import {
  useModal,
  Button,
} from '@logtrade-technology-ab/logtrade-react-components'

import { Loading, CenterMessage, withSuspense } from 'components/layout'
import { LocationModal } from 'components/modals'
import api from 'api'

const LocationsOverview = withSuspense(lazy(() => import('./Overview')))
const LocationDetails = withSuspense(lazy(() => import('./Details')))

const NoLocationsView = () => {
  const { showModal } = useModal()

  return (
    <CenterMessage
      icon="locationMarker"
      title="No hubs just yet."
      message="Logtrade Hubs manage locations where autonomous, off-peak deliveries and
        live data can be optimized for better production planning."
    >
      <Button onPress={() => showModal(LocationModal)}>Add new hub</Button>
    </CenterMessage>
  )
}

const Details = () => {
  const { id } = useParams()

  const [location, { loading }] = api.Locations.getById({ id })
  const [gateways, { gatewaysLoading }] = api.Gateways.getAll()

  if (location) {
    return <LocationDetails location={location} gateways={gateways} />
  }

  if (loading || gatewaysLoading) return <Loading />

  return <Navigate to=".." />
}

const Overview = () => {
  const [locations, { loading, refetch }] = api.Locations.getAll()

  const anyLocations = !!locations.length

  if (anyLocations)
    return <LocationsOverview locations={locations} refetch={refetch} />

  if (loading) return <Loading />

  return <NoLocationsView />
}

export default {
  Overview,
  Details,
}
