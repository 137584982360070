import { graphql } from 'gql'

export const CreateLocationMutation = graphql(`
  mutation CreateLocation($data: CreateLocationInput!) {
    createLocation(data: $data) {
      id
    }
  }
`)

export const AssociateLocationWithGatewayMutation = graphql(`
  mutation AssociateLocationWithGateway(
    $data: AssociateLocationWithGatewayInput!
  ) {
    associateLocationWithGateway(data: $data) {
      entityId
      associatedIolIdentity
    }
  }
`)

export const UpdateLocationMutation = graphql(`
  mutation UpdateLocation($data: UpdateLocationInput!) {
    updateLocation(data: $data) {
      id
    }
  }
`)

export const SaveLockAccessPolicyMutation = graphql(`
  mutation SaveLockAccessPolicy($data: SaveLockAccessPolicyInput!) {
    saveLockAccessPolicy(data: $data) {
      policy {
        operationMode
      }
    }
  }
`)
