import AuthAPI from './auth'
import ProfileAPI from './profile'
import VehicleAPI from './vehicles'
import TradeUnitAPI from './tradeUnits'
import ShipmentAPI from './shipments'
import SensorAPI from './sensors'
import GatewaysAPI from './gateways'
import LocationsAPI from './locations'
import ConnectionsAPI from './connections'
import OrganizationAPI from './organization'
import VolvoAPI from './volvoVehicles'

import { setToken, restore, purge, apolloClient } from './client'

export default {
  Auth: AuthAPI,
  Profile: ProfileAPI,
  Vehicles: VehicleAPI,
  TradeUnits: TradeUnitAPI,
  Shipments: ShipmentAPI,
  Sensors: SensorAPI,
  Gateways: GatewaysAPI,
  Locations: LocationsAPI,
  Connections: ConnectionsAPI,
  Organization: OrganizationAPI,
  Volvo: VolvoAPI,
  client: apolloClient,
  restore,
  purge,
  setToken,
}
