import React, { useEffect, useState } from 'react'
import {
  Modal,
  Button,
  HR,
  Text,
} from '@logtrade-technology-ab/logtrade-react-components'
import api from 'api'
import { usePoller } from 'features/hooks'

const SetupIolModal = ({ onClose, ...props }) => {
  const [clicked, setClicked] = useState(false)

  const [{ isConnectedToIol }, { refetch }] = api.Organization.get()

  const [connect, { loading }] = api.Organization.connectToIol()

  usePoller(refetch, { condition: clicked })

  useEffect(() => {
    if (isConnectedToIol) onClose()
  }, [isConnectedToIol])

  const onClick = () => {
    setClicked(true)
    connect({
      onCompleted: (data) => {
        const url = data?.connectOrganizationToIol?.connectionUrl
        if (data) window.open(url, '_blank').focus()
      },
    })
  }

  return (
    <Modal
      title="Setup IOL connection"
      onClose={onClose}
      isDismissable
      {...props}
    >
      <HR />
      <div className="flex flex-col items-center gap-10 p-8 !pb-12">
        <Text className="text-center">
          Your account is currently not connected to IOL. Click on the button
          below and follow the setup to authorize the connection.
        </Text>
        <Button
          onPress={onClick}
          isLoading={loading}
          tint="accent"
          className="w-min"
        >
          Connect to IOL
        </Button>
      </div>
    </Modal>
  )
}

SetupIolModal.propTypes = Modal.propTypes

export default SetupIolModal
