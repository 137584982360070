import React from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'
import { useActiveRoutes } from 'features/hooks'
import { Link, Text } from '@logtrade-technology-ab/logtrade-react-components'

const crumbStyle = '!text-main-5 !text-xs user-select-none'

const BreadCrumb = ({ current, className, ...props }) => {
  const { routes, root } = useActiveRoutes()

  const namedRoutes = routes.filter(({ name }) => !!name)

  return (
    <span
      className={twMerge('inline-flex user-select-none', className)}
      {...props}
    >
      {namedRoutes.map((route) => (
        // Why do I need a key on a fragment?
        <React.Fragment key={route.path}>
          {route === root ? (
            <Text className={crumbStyle}>{route.name}</Text>
          ) : (
            <Link className={crumbStyle} to={route.path}>
              {route.name}
            </Link>
          )}
          <Text className={twMerge(crumbStyle, 'ml-1 mr-2')}>/</Text>
        </React.Fragment>
      ))}
      <Text className={crumbStyle}>{current}</Text>
    </span>
  )
}

BreadCrumb.propTypes = {
  current: PropTypes.string,
  className: PropTypes.string,
}

export default BreadCrumb
