/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Text,
  HeroIcon,
  Checkbox,
} from '@logtrade-technology-ab/logtrade-react-components'

// eslint-disable-next-line react/prop-types
const Tooltip = ({ text, children }) => {
  const [show, setShow] = useState(false)

  return (
    <div
      className="relative"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      {show && (
        <Text className="absolute top-[105%] left-[120%] bg-main-10 p-4 border border-main-9 text-xs shadow-xl z-50 w-[200px] rounded">
          {text}
        </Text>
      )}
    </div>
  )
}

const DataChoice = ({ formControl: { control }, ...props }) => {
  const dataSource = useMemo(() => [
    {
      value: 'trucks',
      label: 'Benefits my Connected Trucks',
      tooltip:
        'This will give you information about any new Shipments and allows you to send status updates on their shipments.',
    },
    {
      value: 'products',
      label: 'Benefits my Connected Products',
      tooltip: 'This will give you information about locations.',
    },
    {
      value: 'hubs',
      label: 'Benefits my Connected Hubs',
      tooltip:
        'This will give you information about any incoming and outgoing shipments to and from any of your locations.',
    },
  ])

  return (
    <div
      className="grid sm:px-8 grid-cols-1 gap-x-2 gap-y-6 auto-rows-min"
      {...props}
    >
      <Text tag="h6" className="text-center">
        What is the purpose of this connection?
      </Text>
      <div className="_sm:px-5 sm:px-10">
        <Text className="!text-base text-center text-main-4 mb-4">
          I want to ask for data that:
        </Text>
        {dataSource.map(({ value, label, tooltip }) => (
          <div key={value} className="flex justify-between items-center mb-2">
            <Checkbox.Control control={control} name={value} className="w-full">
              {label}
            </Checkbox.Control>
            <Tooltip text={tooltip}>
              <HeroIcon className="h-4 text-main-4" icon="informationCircle" />
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  )
}

DataChoice.propTypes = {
  formControl: PropTypes.object,
}

export default DataChoice
