import React from 'react'
import PropTypes from 'prop-types'
import { Timeline } from '@logtrade-technology-ab/logtrade-react-components'
import { FixedSizeList as List } from 'react-window'

const modifier = 10
const pxModifier = `${modifier}px`

const Row =
  (getProps) =>
  ({ index, style, isScrolling: _isScrolling, ...props }) =>
    (
      <Timeline.Item
        {...getProps(index)}
        style={{
          ...style,
          left: pxModifier,
          width: `calc(100% - ${pxModifier})`,
          transform: 'none !important',
          top: style.top + modifier,
          height: style.height - modifier,
        }}
        {...props}
      />
    )

const EventTimeline = ({ items, ...props }) => (
  <List
    outerElementType={Timeline}
    itemCount={items.length}
    width="100%"
    height={400}
    itemSize={56}
    {...props}
  >
    {Row((i) => items[i])}
  </List>
)

EventTimeline.propTypes = {
  pageSize: PropTypes.number,
  items: PropTypes.array.isRequired,
}

export default EventTimeline
