import React from 'react'
import PropTypes from 'prop-types'
import {
  Text,
  TextField,
} from '@logtrade-technology-ab/logtrade-react-components'

const PartnerChoice = ({ formControl: { control }, ...props }) => {
  return (
    <div
      className="grid sm:px-8 grid-cols-2 gap-x-2 gap-y-6 auto-rows-min"
      {...props}
    >
      <Text tag="h6" className="col-span-full text-center">
        Which Logtrade Partner do you wish to connect to?
      </Text>
      <TextField.Control
        control={control}
        name="connectionName"
        placeholder="Partner name"
      />
      <TextField.Control
        control={control}
        name="partnerEmailAddress"
        placeholder="Partner email"
      />
    </div>
  )
}

PartnerChoice.propTypes = {
  formControl: PropTypes.object,
}

export default PartnerChoice
