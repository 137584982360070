/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  HR,
  HeroIcon,
} from '@logtrade-technology-ab/logtrade-react-components'

const orderToColor = (o) => {
  switch (o) {
    case 0:
      return '#2364AA'
    case 1:
      return '#3DA5D9'
    case 2:
      return '#73BFB8'
    case 3:
      return '#FEC601'
    case 4:
      return '#EA7317'
    default:
      return orderToColor(o - 4)
  }
}

const sortedEntries = (obj) =>
  Object.entries(obj).sort((a, b) => {
    const aKey = a[0].toLowerCase()
    const bKey = b[0].toLowerCase()

    if (aKey === 'id') return -1
    if (bKey === 'id') return 1

    const aVal = a[1]
    const bVal = b[1]

    const isNone = (v) => v === undefined || v === null

    const aType =
      isNone(aVal) || ['string', 'number', 'boolean'].includes(typeof aVal)
        ? 'primitive'
        : Array.isArray(aVal)
        ? 'array'
        : typeof aVal

    const bType =
      isNone(bVal) || ['string', 'number', 'boolean'].includes(typeof bVal)
        ? 'primitive'
        : Array.isArray(bVal)
        ? 'array'
        : typeof bVal

    if (aType !== bType) {
      // Sort by primitive type first
      if (aType === 'primitive') return -1
      if (bType === 'primitive') return 1

      // Sort by object type second
      if (aType === 'object') return -1
      if (bType === 'object') return 1

      // Sort by array type last
      if (aType === 'array') return 1
      if (bType === 'array') return -1
    }
    // If types are equal, sort by key name
    return aKey.localeCompare(bKey)
  })

const DataRow = ({ title, value }) => (
  <>
    <span className="font-bold">{title}:</span>
    <span className="break-words font-code border px-1">{value ?? '-'}</span>
  </>
)

const ShowData = ({ data, title, order = 0 }) => {
  const [open, setOpen] = useState(true)

  const isRow = (v) =>
    v === undefined || v === null || ['string', 'number'].includes(typeof v)

  return (
    <>
      {title && order && (
        <div
          onClick={() => setOpen((o) => !o)}
          className="flex select-none justify-between font-bold pt-5 pb-2 col-span-full cursor-pointer"
          style={{ paddingLeft: `${order - 1}rem` }}
        >
          <span>{title}</span>
          <HeroIcon
            className="h-4"
            icon={open ? 'chevron-down' : 'chevron-left'}
          />
        </div>
      )}
      {open && (
        <div
          className="grid text-xs grid-cols-[min-content_minmax(0,5fr)] gap-3"
          style={{
            marginLeft: `${order}rem`,
            paddingLeft: `${order ? 10 : 0}px`,
            gridColumn: order && '1/-1',
            borderLeft: order && `solid 1.5px ${orderToColor(order)}`,
          }}
        >
          {typeof data === 'object'
            ? sortedEntries(data)
                .filter(([k]) => k !== '__typename')
                .map(([key, value], i) => {
                  if (isRow(value))
                    return <DataRow key={key + i} title={key} value={value} />

                  const arr = Array.isArray(value)

                  return (arr ? value : [value]).map((v, j) => (
                    <ShowData
                      key={`${key}${i}${j}`}
                      title={arr ? `${key}[${j}]` : key}
                      data={v}
                      order={order + 1}
                    />
                  ))
                })
            : 'Error'}
        </div>
      )}
    </>
  )
}

const IolDataModal = ({ data, type, onClose, ...props }) => {
  let dataToShow

  if (type === 'TradeUnit') {
    dataToShow = {
      ...data,
      events: data.events.map((event) => ({
        ...event,
        data: {
          ...event.data,
          location: event.data.optLocation ?? event.data.location,
        },
      })),
    }
  } else {
    dataToShow = data
  }

  return (
    <Modal
      title={`Raw API data for ${type}`}
      onClose={onClose}
      isDismissable
      className="lg:!w-[900px] md:!w-[700px] sm:!w-[500px]"
      {...props}
    >
      <HR />
      <div className="p-8 max-h-[70vh] w-full overflow-y-auto overflow-x-hidden">
        <ShowData data={dataToShow} />
      </div>
    </Modal>
  )
}

IolDataModal.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  ...Modal.propTypes,
}

export default IolDataModal
