import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Pages from 'components/pages'
import { CenterMessage } from 'components/elements'
import { Loading } from 'components/layout'
import { Button } from '@logtrade-technology-ab/logtrade-react-components'
import api from 'api'

const FourOhFour = () => {
  const navigate = useNavigate()
  const hasFinished = api.Organization.finished()

  return !hasFinished ? (
    <Loading />
  ) : (
    <CenterMessage icon="ban" title="404 :(" message="This page does not exist">
      <Button onPress={() => navigate('/', { replace: true })}>
        Back to safety
      </Button>
    </CenterMessage>
  )
}

// The 'name' property is used as name when referring to the route
// The 'asset' property is used to resolve paths to asset details

const useAppRoutes = (absolute = false) => {
  const [organization] = api.Organization.get()

  const { hasConnectedTrucks, hasConnectedHubs, hasConnectedProducts } =
    organization

  const appRoutes = useMemo(
    () =>
      [
        hasConnectedProducts && {
          name: 'Connected Products',
          path: 'products',
          children: [
            {
              name: 'Products',
              path: 'products',
              icon: 'view-grid-add',
              children: [
                {
                  path: '',
                  element: <Pages.TradeUnits.Overview />,
                },
                {
                  path: ':id',
                  asset: 'tradeUnit',
                  element: <Pages.TradeUnits.Details />,
                },
              ],
            },
            {
              name: 'Shipments',
              path: 'shipments',
              icon: 'archive',
              children: [
                {
                  path: '',
                  element: <Pages.TradeUnits.ShipmentsOverview />,
                },
                {
                  path: ':id',
                  asset: 'shipment',
                  element: <Pages.Shipments.Details />,
                },
                {
                  path: ':subid',
                  asset: 'productShipment',
                  element: <Pages.TradeUnits.ShipmentDetails />,
                },
              ],
            },
            {
              name: 'Settings',
              path: 'settings',
              icon: 'cog',
              children: [
                {
                  path: '',
                  element: <Pages.TradeUnits.Settings />,
                },
              ],
            },
          ],
        },
        hasConnectedTrucks && {
          name: 'Connected Trucks',
          path: 'trucks',
          children: [
            {
              name: 'Shipments',
              path: 'shipments',
              icon: 'archive',
              children: [
                {
                  path: '',
                  element: <Pages.Shipments.Overview />,
                },
                {
                  path: ':id',
                  asset: 'shipment',
                  element: <Pages.Shipments.Details />,
                },
              ],
            },
            {
              name: 'Trucks',
              path: 'trucks',
              icon: 'truck',
              children: [
                {
                  path: '',
                  element: <Pages.Trucks.Overview />,
                },
                {
                  path: ':id',
                  asset: 'vehicle',
                  element: <Pages.Trucks.Details />,
                },
              ],
            },
          ],
        },
        hasConnectedHubs && {
          name: 'Connected Hubs',
          path: 'hubs',
          children: [
            {
              name: 'Locations',
              path: 'locations',
              icon: 'location-marker',
              children: [
                {
                  path: '',
                  element: <Pages.Locations.Overview />,
                },
                {
                  path: ':id',
                  asset: 'location',
                  element: <Pages.Locations.Details />,
                },
              ],
            },
          ],
        },
        {
          name: 'Technical Assets',
          path: 'technical-assets',
          children: [
            {
              name: 'Gateways',
              path: 'gateways',
              icon: 'wifi',
              children: [
                {
                  path: '',
                  element: <Pages.Gateways.Overview />,
                },
              ],
            },
            {
              name: 'Sensors',
              path: 'sensors',
              icon: 'rss',
              children: [
                {
                  path: '',
                  element: <Pages.Sensors.Overview />,
                },
                {
                  path: ':id',
                  asset: 'sensor',
                  element: <Pages.Sensors.Details />,
                },
              ],
            },
          ],
        },
        {
          name: 'Admin',
          path: 'admin',
          children: [
            {
              name: 'Users',
              path: 'users',
              icon: 'users',
              children: [
                {
                  path: '',
                  element: <Pages.Users.Overview />,
                },
              ],
            },
            {
              name: 'Data sharing',
              path: 'datasharing',
              icon: 'collection',
              children: [
                {
                  path: '',
                  element: <Pages.Connections.Overview />,
                },
              ],
            },
            {
              name: 'Settings',
              path: 'settings',
              icon: 'cog',
              children: [
                {
                  path: '',
                  element: <Pages.Settings />,
                },
              ],
            },
          ],
        },
        {
          path: '/',
          element: <Pages.Landing />,
        },
        {
          path: '*',
          element: <FourOhFour />,
        },
      ].filter(Boolean),
    [hasConnectedTrucks, hasConnectedHubs, hasConnectedProducts]
  )

  return absolute ? absolutePaths(appRoutes) : appRoutes
}

export default useAppRoutes

const absolutePaths = (routes, acc = '') =>
  routes.map((route) => ({
    ...route,
    children: route.children
      ? absolutePaths(route.children, `${acc}/${route.path}`)
      : undefined,
    path: `${acc}/${route.path}`,
  }))
