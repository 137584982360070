import { NetworkStatus } from '@apollo/client'
import { useBoundState } from 'features/PageContext'
import { useEffect, useMemo, useState } from 'react'
import { keyp } from 'utils'

const toApiOrder = (order) => (order === 'ascend' ? 'Asc' : 'Desc')

const toPageNum = ({ offset, limit }) => offset / limit + 1

const isDifferent = (newValue, oldValue) =>
  JSON.stringify(newValue) !== JSON.stringify(oldValue)

/**
 *
 * This hook is the bridge between paginated/sorted/filtered api queries and antd table
 * @param {object} apiModule Api module with .name prop and .getPartial & .getCount functions
 * @param {object} config Configuration with pageSize, columns & searchValue keys
 * @returns {array} [data, state] where state is an extended useQuery state
 */
const usePartial = ({ getPartial, getCount, name }, config) => {
  const { pageSize, columns, searchValue } = config

  // Find a column with defaultSortOrder
  const sortColumn = columns.find((col) => col.defaultSortOrder)

  // Query variables state
  const [variables, setVariables] = useBoundState(name, {
    pagination: {
      limit: pageSize ?? 10,
      offset: 0,
    },
    sorting: sortColumn && {
      field: sortColumn.dataIndex,
      order: toApiOrder(sortColumn.defaultSortOrder),
    },
    filter: searchValue && {
      genericSearchValue: searchValue,
    },
  })

  const calcPagination = (page) => ({
    offset: (page - 1) * variables.pagination.limit,
    limit: variables.pagination.limit,
  })

  // Update query variables on search value change
  useEffect(() => {
    // if (searchValue)
    const pagination = calcPagination(1)
    const filter = searchValue && { genericSearchValue: searchValue }
    setVariables((old) => ({ ...old, pagination, filter }))
  }, [searchValue])

  // Fetch a new page by updating state
  const updateVariables = useMemo(() => (page, sorting) => {
    setVariables(({ filter, sorting: oldSorting }) => ({
      pagination: calcPagination(isDifferent(oldSorting, sorting) ? 1 : page),
      sorting,
      filter,
    }))
  })

  // Handle antd table change
  const onTableChange = useMemo(() => (pagination, _filter, sorter) => {
    const { current } = pagination

    const sorting = sorter && {
      field: sorter.field,
      order: toApiOrder(sorter.order),
    }

    updateVariables(current, sorting)
  })

  // True after initial query call has completed
  const [initCall, setInitCall] = useState(false)

  const [data, state] = getPartial({
    variables: keyp(variables),
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setInitCall(true),
  })

  const [count] = getCount({
    variables: keyp({
      filter: variables.filter,
    }),
  })

  // Extend the client state
  const extState = {
    ...state,
    onTableChange,
    loading:
      state.networkStatus === NetworkStatus.setVariables || state.loading,
    currentCount: count,
    currentPage: toPageNum(variables.pagination),
    ready: initCall || data.length,
  }

  return [data, extState]
}

export default usePartial
