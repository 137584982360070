import { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  GetShipmentCountQuery,
  GetShipmentsQuery,
  GetShipmentQuery,
  GetAssociatedShipmentsQuery,
} from './queries'
import { combine } from 'utils'
import { QueryOptions } from 'api/types'
export * from './queries'

const api = {
  name: 'shipments',

  // Get single shipmnent by ID
  getById: (
    variables: { id: string },
    options?: QueryOptions<typeof GetShipmentQuery>
  ) => {
    const state = useQuery(GetShipmentQuery, {
      ...(options as object),
      variables,
    })
    const shipment = state.data?.shipment

    return [shipment, state] as const
  },

  // Get all shipments
  getAll: (options: QueryOptions<typeof GetShipmentsQuery>) => {
    const state = useQuery(GetShipmentsQuery, options as object)
    const shipments = state.data?.shipments ?? []

    return [shipments, state] as const
  },

  // Get partial shipments
  getPartial: (options: QueryOptions<typeof GetShipmentsQuery>) => {
    const [oldShipments, setOldShipments] = useState([])

    const [shipments, state] = api.getAll({
      onCompleted: combine(options.onCompleted, (data) => {
        setOldShipments(data?.shipments ?? [])
      }),
      ...options,
    })

    return [shipments.length ? shipments : oldShipments, state] as const
  },

  // Get total count of shipments
  getCount: (options?: QueryOptions<typeof GetShipmentCountQuery>) => {
    const state = useQuery(GetShipmentCountQuery, options as unknown)
    const count = state.data?.shipmentCount ?? null

    return [count, state] as const
  },

  getForTradeUnit: (
    variables: { tradeUnitId: string },
    options?: QueryOptions<typeof GetAssociatedShipmentsQuery>
  ) => {
    const state = useQuery(GetAssociatedShipmentsQuery, {
      ...(options as object),
      variables,
    })
    const shipments = state.data?.tradeUnitShipmentAssociations ?? []

    return [shipments, state] as const
  },
}

export default api
