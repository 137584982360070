import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import {
  Login as LogtradeLogin,
  useLogtradeAuth,
} from '@logtrade-technology-ab/logtrade-react-components'

import api from 'api'

const LoginPage = () => {
  const { isAuthenticated } = useLogtradeAuth()
  const location = useLocation()

  const Login = LogtradeLogin.FullPage

  if (isAuthenticated) {
    const state = location?.state
    const target = state?.from && state.from !== 'logout' ? state.from : '/'
    return <Navigate to={target} />
  }

  const loginRequest = async ({ email, password }) => {
    const { token } = await api.Auth.login({ email, password })

    const { hasConnectedTrucks, hasConnectedHubs, hasConnectedProducts } =
      await api.Auth.getOrganization(token)

    const canLogin =
      hasConnectedHubs || hasConnectedProducts || hasConnectedTrucks

    const error = {
      code: 'Custom',
      message: 'Account does not have connected goods',
    }

    return canLogin ? token : error
  }

  return <Login request={loginRequest} />
}

export default LoginPage
