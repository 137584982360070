import React, { lazy } from 'react'

import { Loading, CenterMessage, withSuspense } from 'components/layout'

import api from 'api'

const GatewaysOverview = withSuspense(lazy(() => import('./Overview')))

const NoGatewaysView = () => (
  <CenterMessage
    icon="wifi"
    title="No gateways just yet."
    message="But as soon as they’re ready, they’ll show up here. If you think there may
    be a mistake, please contact your administrator."
  />
)

const Overview = () => {
  const [gateways, { loading, refetch }] = api.Gateways.getAll()
  const anyGateways = !!gateways.length

  if (anyGateways)
    return <GatewaysOverview gateways={gateways} refetch={refetch} />

  if (loading) return <Loading />

  return <NoGatewaysView />
}

export default {
  Overview,
}
