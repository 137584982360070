import { graphql } from 'gql'

graphql(`
  fragment LocationFragment on Location {
    id
    name
    iolIdentity
    address1
    zipCode
    city
    countryCode
    createdAt
    locks {
      id
      iolIdentity
      assetIdentifier
      createdAt
      manufacturer
      accessHistory {
        request {
          sentAt
          actionName
        }
        response {
          receivedAt
          accessGranted
          denialReason
        }
        testMode
      }
      accessPolicy {
        enabled
        operationMode
        carrierWhitelist
        carrierWhitelistIgnored
        driverAction
        enabled
        activeShipment
        shipmentSourceOrDestination
        tradeUnitMaxDistance
        tradeUnitMaxDistanceIgnored
        tradeUnitPresenceDetectedBy
        tradeUnitPresenceIgnored
      }
    }
    gateway {
      id
      manufacturer
      model
      uniqueIdentifier
      iolIdentity
    }
    geoLocation {
      lat
      lng
    }
    incomingShipments {
      id
      status
      shipmentNumber
      sourceAddress {
        ...ShipmentAddressFragment
      }
    }
  }
`)

export const GetLocationsQuery = graphql(`
  query GetLocations {
    locations {
      ...LocationFragment
    }
  }
`)
