import { graphql } from 'gql/gql'

graphql(`
  fragment EventLocationFragment on TradeUnitEventLocationData {
    address1
    city
    iolIdentity
    geoLocation {
      lat
      lng
    }
  }
`)

graphql(`
  fragment SmallTradeUnitFragment on TradeUnit {
    id
    itemIdentity
    createdAt
    iolIdentity
    description
    serialNumber
    lastKnownPosition {
      lat
      lng
    }
  }
`)

graphql(`
  fragment BaseTradeUnitFragment on TradeUnit {
    id
    itemIdentity
    createdAt
    iolIdentity
    quantity
    quantityUnit
    unitWeight
    lastUpdateAt
    lastUpdateDescription
    description
    serialNumber
    shipments {
      id
      status
      shipmentNumber
    }
    sensors {
      id
      assetIdentifier
      associatedAt
      disAssociatedAt
    }
  }
`)

graphql(`
  fragment FullTradeUnitFragment on TradeUnit {
    ...BaseTradeUnitFragment
    expiryDate
    lastKnownPosition {
      lat
      lng
    }
    handlingRestrictions {
      name
      unit
      minValue
      maxValue
    }
    events {
      geoPoint {
        lat
        lng
      }
      createdAt
      eventTimestamp
      eventType
      data {
        ... on TradeUnitConnectedToSensor {
          eventType
          sensor {
            id
          }
        }
        ... on TradeUnitDisconnectedFromSensor {
          eventType
          sensor {
            id
          }
        }
        ... on TradeUnitArrived {
          eventType
          location {
            ...EventLocationFragment
          }
        }
        ... on TradeUnitDeparted {
          eventType
          location {
            ...EventLocationFragment
          }
        }
        ... on TradeUnitPickedUp {
          eventType
          optLocation: location {
            ...EventLocationFragment
          }
        }
        ... on TradeUnitDelivered {
          eventType
          optLocation: location {
            ...EventLocationFragment
          }
        }
      }
    }
    sensors {
      iolIdentity
      assetIdentifier
      uniqueIdentifier
      manufacturer
      model
      associatedAt
      disAssociatedAt
      events {
        iolIdentity
        eventTimestamp
        properties {
          name
          value
        }
        geoLocation {
          lat
          lng
        }
        measurementsNew {
          name
          unit
          value
        }
      }
      lastAddress {
        timestamp
        geoLocation {
          lat
          lng
        }
        address {
          city
        }
      }
    }
    shipments {
      ...ShipmentFragment
    }
  }
`)

export const GetSmallTradeUnitsQuery = graphql(`
  query GetSmallTradeUnits {
    tradeUnits {
      ...SmallTradeUnitFragment
    }
  }
`)

export const GetTradeUnitsQuery = graphql(`
  query GetTradeUnits {
    tradeUnits {
      ...BaseTradeUnitFragment
    }
  }
`)

export const GetTradeUnitQuery = graphql(`
  query GetTradeUnit($id: String!) {
    tradeUnit(id: $id) {
      ...FullTradeUnitFragment
    }
  }
`)

export const GET_TRADE_UNITS_PAGINATION = graphql(`
  query GetTradeUnitsWithPagination(
    $page: Float = 1
    $pageSize: Float = 10
    $orderBy: String = "createdAt"
    $orderDirection: String = "ASC"
    $search: String
    $category: String
  ) {
    tradeUnitsWithPagination(
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
      category: $category
    ) {
      ...BaseTradeUnitFragment
    }
  }
`)

export const GetTradeUnitsCountQuery = graphql(`
  query GetTradeUnitsCount($search: String) {
    tradeUnitsCount(search: $search) {
      total
      totalActive
      totalInactive
    }
  }
`)
