import React, { lazy } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Loading, CenterMessage, withSuspense } from 'components/layout'
import api from 'api'

const SensorsOverview = withSuspense(lazy(() => import('./Overview')))
const SensorDetails = withSuspense(lazy(() => import('./Details')))

const NoSensorsView = () => (
  <CenterMessage
    icon="wifi"
    title="No sensors just yet."
    message="But as soon as they’re ready, they’ll show up here. If you think there may
  be a mistake, please contact your administrator."
  />
)

const Details = () => {
  const { id } = useParams()
  const [locations] = api.Locations.getAll()
  const [sensor, { loading }] = api.Sensors.getById({ id })

  if (sensor) {
    return <SensorDetails sensors={[sensor]} locations={locations} />
  }
  if (loading) return <Loading />

  return <Navigate to=".." />
}

const Overview = () => {
  const [sensors, { loading, refetch }] = api.Sensors.getAll()
  const realSensors = Array.isArray(sensors)
    ? sensors
        .filter((s) => !s.isVirtual)
        .map((sensor) => ({
          ...sensor,
        }))
    : []
  if (realSensors?.length > 0) {
    return <SensorsOverview sensors={realSensors} refetch={refetch} />
  }

  if (loading) return <Loading />

  return <NoSensorsView />
}

export default {
  Details,
  Overview,
}
