import React, { lazy } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Loading, CenterMessage, withSuspense } from 'components/layout'
import api from 'api'

const TradeUnitSettings = withSuspense(lazy(() => import('./Settings')))
const TradeUnitDetails = withSuspense(lazy(() => import('./Details')))
const TradeUnitsOverview = withSuspense(lazy(() => import('./Overview')))
const TradeUnitShipmentDetails = withSuspense(lazy(() => import('./Shipment')))
const TradeUnitShipmentsOverview = withSuspense(
  lazy(() => import('./Shipments'))
)

const NoShipmentsView = () => (
  <CenterMessage
    icon="archive"
    title="No shipments just yet."
    message="But as soon as they’re ready, they’ll show up here. If you think there may
      be a mistake, please contact your administrator."
  />
)

const Settings = () => {
  return <TradeUnitSettings />
}

const Overview = () => {
  return <TradeUnitsOverview />
}

const Details = () => {
  const { id } = useParams()
  const [locations] = api.Locations.getAll()

  const [tradeUnit, { loading }] = api.TradeUnits.getById({ id })

  if (tradeUnit) {
    return <TradeUnitDetails tradeUnit={tradeUnit} locations={locations} />
  }

  if (loading) return <Loading />

  return <Navigate to=".." />
}

const ShipmentDetails = () => {
  const { id } = useParams()
  const [locations] = api.Locations.getAll()
  const [tradeUnit, { loading }] = api.TradeUnits.getById({ id })

  if (tradeUnit) {
    return (
      <TradeUnitShipmentDetails tradeUnit={tradeUnit} locations={locations} />
    )
  }
  if (loading) return <Loading />
  return <Navigate to=".." />
}

const ShipmentsOverview = () => {
  const [count, { loading }] = api.Shipments.getCount()

  if (count || loading) return <TradeUnitShipmentsOverview totalCount={count} />

  return <NoShipmentsView />
}

export default {
  Overview,
  Details,
  ShipmentDetails,
  ShipmentsOverview,
  Settings,
}
