import {
  SaveVolvoCredentialsMutation,
  ActivateVolvoVehicleMutation,
  DeactivateVolvoVehicleMutation,
} from './mutations'
import { GetVolvoVehiclesQuery } from './queries'
import { withReport } from 'api/utils'
import { useMutation, useQuery } from '@apollo/client'
import { GetVehiclesQuery } from 'api/vehicles'
import { useEffect, useState } from 'react'
import { MutationOptions, QueryOptions } from 'api/types'
import mockApi from './mocks'
export * from './queries'
export * from './mutations'

const api = {
  // Get all Volvo vehicles
  getAll: (options?: QueryOptions<typeof GetVolvoVehiclesQuery>) => {
    const state = useQuery(GetVolvoVehiclesQuery, options as unknown)
    const vehicles = state.data?.volvoVehicles ?? []

    return [vehicles, state] as const
  },

  // Save Volvo credentials into account
  login: (
    initOptions?: MutationOptions<typeof SaveVolvoCredentialsMutation>
  ) => {
    const [mutate, state] = useMutation(SaveVolvoCredentialsMutation, {
      refetchQueries: [{ query: GetVolvoVehiclesQuery }],
    })

    const call = (
      data: {
        username: string
        password: string
      },
      options?: MutationOptions<typeof SaveVolvoCredentialsMutation>
    ) => {
      return mutate({ ...initOptions, ...options, variables: { data } })
    }

    return [call, state] as const
  },

  // Activate single Volvo vehicle
  activate: withReport<typeof ActivateVolvoVehicleMutation>()((addReport) => {
    const [mutate, state] = useMutation(ActivateVolvoVehicleMutation, {
      refetchQueries: [
        { query: GetVolvoVehiclesQuery },
        { query: GetVehiclesQuery },
      ],
    })

    const call = (
      data: {
        uniqueIdentifier: string
        name: string
        manufacturer: string
        model: string
      },
      options?: MutationOptions<typeof ActivateVolvoVehicleMutation>
    ) => {
      const opts = addReport('Activated Volvo truck', options)

      return mutate({ ...opts, variables: { data } })
    }

    return [call, state] as const
  }),

  // Deactivate single Volvo vehicle
  deactivate: withReport<typeof DeactivateVolvoVehicleMutation>()(
    (addReport) => {
      const [mutate, state] = useMutation(DeactivateVolvoVehicleMutation, {
        refetchQueries: [
          { query: GetVolvoVehiclesQuery },
          { query: GetVehiclesQuery },
        ],
      })

      const call = (
        {
          connectorAsset: { assetId },
        }: { connectorAsset: { assetId: string } },
        options?: MutationOptions<typeof DeactivateVolvoVehicleMutation>
      ) => {
        const data = { assetId }
        const opts = addReport('Deactivated Volvo truck', options)

        return mutate({ ...opts, variables: { data } })
      }

      return [call, state] as const
    }
  ),

  // Messy hacky solution to activate/deactivate multiple volvo vehicles
  // The backend should provide a mutation for this in the future
  import: withReport<
    typeof ActivateVolvoVehicleMutation & typeof DeactivateVolvoVehicleMutation
  >()((addReport) => {
    const [act, _as] = api.activate()
    const [dea, _ds] = api.deactivate()

    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [length, setLength] = useState(0)
    const [aDone, setADone] = useState(0)
    const [dDone, setDDone] = useState(0)

    const appendError = (err) => setErrors((errs) => [...errs, err])
    const addADone = () => setADone((d) => d + 1)
    const addDDone = () => setDDone((d) => d + 1)

    useEffect(() => {
      if ((aDone || dDone) && length && aDone + dDone === length) {
        setLoading(false)
        addReport(
          `Activated: ${aDone} / Deactivated: ${dDone} Volvo trucks`
        ).onCompleted(null)
      }
    }, [length, aDone, dDone])

    const call = ({
      activate,
      deactivate,
    }: {
      activate: {
        uniqueIdentifier: string
        name: string
        manufacturer: string
        model: string
      }[]
      deactivate: { connectorAsset: { assetId: string } }[]
    }) => {
      setLength((activate?.length ?? 0) + (deactivate?.length ?? 0))
      setLoading(true)

      activate.forEach((vehicle) =>
        act(vehicle, {
          onError: appendError,
          onCompleted: addADone,
        })
      )
      deactivate.forEach((vehicle) =>
        dea(vehicle, {
          onError: appendError,
          onCompleted: addDDone,
        })
      )
    }

    return [call, { errors, loading }]
  }),
}

export default Object.assign(api, {
  mock: mockApi,
})
