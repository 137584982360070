import tailwindConfig from '@logtrade-technology-ab/logtrade-react-components/tailwind.config.js'
import resolveConfig from 'tailwindcss/resolveConfig'

const fullConfig = resolveConfig(tailwindConfig)

export const { theme } = fullConfig

// eslint-disable-next-line no-undef
export const isProd = process.env.REACT_APP_MODE === 'prod'

// export const iolIdProperty = (data) => ({
//   label: (
//     <span className="flex items-center gap-1">
//       IOL ID
//       {data.iolIdentity && (
//         <Button
//           unstyled
//           onPress={() => navigator.clipboard.writeText(data.iolIdentity)}
//           className="text-accent-5"
//         >
//           <HeroIcon icon="duplicate" className="h-3" />
//         </Button>
//       )}
//     </span>
//   ),
//   value: data.iolIdentity ?? '-', //data.iolIdentity?.split('/').reverse()[0] ?? '-',
// })
