import { graphql } from 'gql'

export const CreateConnectionMutation = graphql(`
  mutation CreateConnection($data: CreateConnectionInput!) {
    createConnection(data: $data) {
      connectionUrl
      didSendEmail
    }
  }
`)
