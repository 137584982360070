// Sorters can be used anywhere, but are primarily designed to work
// with table column definitions. Every exported sorter will take any
// number of steps as arguments, to resolve the actual value from an object,
// and return a function which compares two objects using the property
// resolved by following the steps (= either string selectors or functions)

// Example:

// const myData = [
//   { name: 'first', prop: { value: 1 }},
//   { name: 'second', prop: { value: 5 }},
//   { name: 'third', prop: { value: 3 }}
// ]

// const sorter = sorters.number('prop', 'value')
// const sorted = myData.sort(sorter)
// out: [first, third, second]

const resolve = (obj, steps) => {
  let value = obj

  steps.forEach((step) => {
    if (typeof step === 'string') {
      value = value?.[step]
    }

    if (typeof step === 'function') {
      value = step(value)
    }
  })

  return value
}

const string =
  (...steps) =>
  (a, b) =>
    (resolve(a, steps) ?? '').localeCompare(resolve(b, steps) ?? '')

const number =
  (...steps) =>
  (a, b) =>
    (resolve(a, steps) ?? 0) - (resolve(b, steps) ?? 0)

const date =
  (...steps) =>
  (a, b) => {
    const da = new Date(resolve(a, steps) ?? null)
    const db = new Date(resolve(b, steps) ?? null)
    return (da > db) - (da < db)
  }

export default {
  string,
  number,
  date,
}
