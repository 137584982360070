import { useQuery } from '@apollo/client'
import { GetMeQuery } from './queries'
import { QueryOptions } from 'api/types'
export * from './queries'

const api = {
  // Get profile
  get: (options?: QueryOptions<typeof GetMeQuery>) => {
    const state = useQuery(GetMeQuery, options as unknown)
    const profile = state.data?.me ?? {}

    return [profile, state]
  },
}

export default api
