import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GLMap, { Layer, Popup } from 'react-map-gl'
import { theme } from 'utils'
import 'mapbox-gl/dist/mapbox-gl.css'

// TODO: move this to gh secrets
export const mapboxPkToken =
  'pk.eyJ1IjoibG9ndHJhZGUtdGVjaG5vbG9neSIsImEiOiJjbDRtazFzbjQwenJnM21vMWhhOGl2Z2t6In0.uEhyuDNVp0UuaL40dswfPg'

const defaultView = {
  latitude: 55.609938678340015,
  longitude: 12.977277507535243,
  zoom: 4,
}

const buildingLayerId = '3d-buildings'

const Map = React.forwardRef(
  (
    {
      children,
      initialView,
      width,
      height,
      setMapRef,
      onClick,
      popupInfo,
      setPopupInfo,

      ...props
    },
    ref
  ) => {
    const [mapRef, setInternalMapRef] = useState(null)
    const mapRefCallback = useCallback((map) => {
      setInternalMapRef(map)
      setMapRef(map)
    })

    useEffect(() => {
      mapRef?.getMap().on('load', () => {
        const firstSymbolId = mapRef
          .getStyle()
          .layers.find(({ type }) => type === 'symbol')?.id

        if (firstSymbolId) mapRef.moveLayer(buildingLayerId, firstSymbolId)
      })
    }, [mapRef])

    return (
      <GLMap
        ref={ref ?? mapRefCallback}
        initialViewState={{ ...defaultView, ...initialView }}
        style={{ width, height }}
        mapStyle="mapbox://styles/mapbox/light-v9"
        mapboxAccessToken={mapboxPkToken}
        onClick={onClick}
        {...props}
      >
        <Layer
          id={buildingLayerId}
          sourceId="composite"
          type="fill-extrusion"
          source-layer="building"
          source="composite"
          layerOptions={{
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 15,
          }}
          paint={{
            'fill-extrusion-color': theme.colors.main[6],
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height'],
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height'],
            ],
            'fill-extrusion-opacity': 0.6,
          }}
        />

        {popupInfo && (
          <Popup
            longitude={popupInfo.lng}
            latitude={popupInfo.lat}
            closeOnClick={false}
            onClose={() => {
              setPopupInfo(null)
            }}
          >
            {popupInfo.sensorLabel}

            <div style={{ margin: '0', fontSize: '14px', color: '#666' }}>
              {new Date(popupInfo.eventTimestamp).toLocaleString('sv-SE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          </Popup>
        )}
        {children}
      </GLMap>
    )
  }
)
Map.displayName = 'Map'

Map.propTypes = {
  initialView: PropTypes.objectOf(PropTypes.number),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setMapRef: PropTypes.func,
  onClick: PropTypes.func,
  popupInfo: PropTypes.object,
  setPopupInfo: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

export default Map
