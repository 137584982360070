import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  Modal,
  Button,
  HeroIcon,
  useStepView,
  HR,
} from '@logtrade-technology-ab/logtrade-react-components'
import PartnerChoice from './PartnerChoice'
import DataChoice from './DataChoice'
import { FormError } from 'components/elements'
import { z } from 'zod'
import api from 'api'

const schema = z.object({
  connectionName: z.string().min(1, {
    message: 'Connection name is required',
  }),
  partnerEmailAddress: z.string().email({
    message: 'Email is invalid',
  }),
})

const ConnectionModal = ({ onClose, ...props }) => {
  const [createConnection, { loading }] = api.Connections.create({
    onError: ({ message }) => setError('request', { message }),
    onCompleted: onClose,
  })

  const formControl = useForm()

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = formControl

  const components = useMemo(() => [PartnerChoice, DataChoice], [])

  const [
    Dots,
    StepComponent,
    { goToPrevStep, canGoToNextStep, canGoToPrevStep, goToNextStep },
  ] = useStepView(components)

  const onSubmit = (input) => {
    if (canGoToNextStep) return goToNextStep()

    // Todo: handle trucks, hubs and products booleans in input when backend supports it

    const { error, success, data } = schema.safeParse(input)

    if (error)
      error.issues.forEach(({ path: [field], message }) =>
        setError(field, { message })
      )

    return success && createConnection(data)
  }

  return (
    <Modal
      title="Data connection request"
      onClose={onClose}
      isDismissable
      {...props}
    >
      <HR />
      <form
        className="grid p-8 mb-3 grid-rows-[minmax(200px,auto)_minmax(50px,auto)]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <StepComponent formControl={formControl} />
        <FormError error={errors.request} />
        <div className="flex items-center relative px-8">
          {canGoToPrevStep && (
            <HeroIcon height="20px" icon="arrowLeft" onClick={goToPrevStep} />
          )}
          <div className="absolute left-1/2 -translate-x-1/2">
            <Dots />
          </div>
          <Button
            type="submit"
            size="sm"
            isLoading={loading}
            onPress={() => clearErrors('request')}
            className="ml-auto"
          >
            {canGoToNextStep ? 'Next' : 'Email request'}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

ConnectionModal.propTypes = Modal.propTypes

export default ConnectionModal
