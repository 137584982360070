import { TypePolicies, FieldPolicy } from '@apollo/client'
import gatewayPolicies from './gateways/policies'

type PaginationArgs = {
  pagination?: {
    offset: number
    limit: number
  }
}

// Get cache page key for pagination policy
const getPageKey = (args: PaginationArgs) => {
  const offset = args.pagination?.offset
  const limit = args.pagination?.limit

  if (offset === undefined || !limit) return 0

  return offset / limit + 1
}

// Standard paginated cache policy
const paginationPolicy: FieldPolicy = {
  keyArgs: ['sorting', 'filter'],
  read: (cache = {}, { args }) => cache[getPageKey(args)],
  merge: (cache = {}, incoming, { args }) => {
    const pageKey = getPageKey(args)

    return {
      ...cache,
      [pageKey]: incoming,
    }
  },
}

const policies: TypePolicies = {
  Sensor: {
    fields: {
      events: {
        merge: (_, incoming) => incoming,
      },
    },
  },
  Query: {
    fields: {
      ...gatewayPolicies,
      shipments: paginationPolicy,
    },
  },
}

export default policies
