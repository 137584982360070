import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import {
  Modal,
  Text,
  HR,
  Button,
  Select,
  TextField,
} from '@logtrade-technology-ab/logtrade-react-components'
import { FormError } from 'components/elements'
import api from 'api'
import { z } from 'zod'

const schema = z.object({
  email: z.string().email(),
  firstName: z.string().min(1, {
    message: 'First name is required',
  }),
  lastName: z.string().min(1, {
    message: 'Last name is required',
  }),
  password: z.string().min(7, {
    message: 'Password must be at least 7 characters',
  }),
  role: z.enum(['Admin', 'Courier']),
})

const UserModal = ({ user, onClose, ...props }) => {
  const [addUser, { loading }] = api.Organization.createUser({
    onError: ({ message }) => setError('request', { message }),
    onCompleted: onClose,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm(
    user && {
      defaultValues: user,
    }
  )

  const onSubmit = (input) => {
    const { error, success, data } = schema.safeParse(input)

    if (error)
      error.issues.forEach(({ path: [field], message }) =>
        setError(field, { message })
      )

    return success && addUser(data)
  }

  return (
    <Modal title="Add new user" onClose={onClose} isDismissable {...props}>
      <HR />
      <form
        autoComplete="off"
        className="grid p-8 !pb-10 gap-x-3 gap-y-4 _md:grid-cols-1 md:grid-cols-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text className="col-span-full !font-normal">Required</Text>
        <TextField.Control
          control={control}
          name="firstName"
          placeholder="First Name"
        />
        <TextField.Control
          control={control}
          name="lastName"
          placeholder="Last Name"
        />
        <TextField.Control
          control={control}
          type="text"
          name="email"
          placeholder="Email"
        />
        <TextField.Control
          control={control}
          type="password"
          name="password"
          placeholder="Password"
        />
        <Select.Control
          control={control}
          name="role"
          options={[
            {
              label: 'Admin',
              value: 'Admin',
            },
            {
              label: 'Courier',
              value: 'Courier',
            },
          ]}
          placeholder="Select Role"
          aria-label="role"
        />
        <FormError className="col-span-full" error={errors.request} />
        <Button
          type="submit"
          isLoading={loading}
          onPress={() => clearErrors('request')}
          className="col-span-full"
        >
          Add user
        </Button>
      </form>
    </Modal>
  )
}

UserModal.propTypes = {
  user: PropTypes.object,
  ...Modal.propTypes,
}

export default UserModal
