import {
  CreateLocationMutation,
  AssociateLocationWithGatewayMutation,
  UpdateLocationMutation,
  SaveLockAccessPolicyMutation,
} from './mutations'
import { useMutation, useQuery } from '@apollo/client'
import { withReport } from 'api/utils'
import { GetLocationsQuery } from './queries'
import { GetGatewaysQuery } from 'api/gateways'
import { InputVariables, MutationOptions, QueryOptions } from 'api/types'
export * from './queries'
export * from './mutations'

const api = {
  // Get single location by ID
  getById: (
    { id }: { id: string },
    options?: QueryOptions<typeof GetLocationsQuery>
  ) => {
    const [locations, state] = api.getAll(options)
    const location = locations.find((l) => l.id === id)

    return [location, state] as const
  },
  associatedGateways: (
    // { location }: { location: string },
    options?: QueryOptions<typeof GetGatewaysQuery>
  ) => {
    const state = useQuery(GetGatewaysQuery, options as unknown)
    const gateways = state.data?.gateways ?? []

    return [gateways, state] as const
  },
  // Get all locations
  getAll: (options?: QueryOptions<typeof GetLocationsQuery>) => {
    const state = useQuery(GetLocationsQuery, options as unknown)
    const locations = state.data?.locations ?? []

    return [locations, state] as const
  },
  // Create new location
  create: withReport<typeof CreateLocationMutation>()((addReport) => {
    const [mutate, state] = useMutation(CreateLocationMutation, {
      refetchQueries: [{ query: GetLocationsQuery }],
    })

    const call = (
      location: InputVariables<typeof CreateLocationMutation>['data'],
      options?: MutationOptions<typeof CreateLocationMutation>
    ) => {
      const opts = addReport('Location created', options)

      return mutate({ ...opts, variables: { data: location } })
    }

    return [call, state] as const
  }),

  // Associate location with gateway
  associateLocationWithGateway: withReport<
    typeof AssociateLocationWithGatewayMutation
  >()((addReport) => {
    const [mutate, state] = useMutation(AssociateLocationWithGatewayMutation, {
      refetchQueries: [
        { query: GetLocationsQuery },
        { query: GetGatewaysQuery },
      ],
    })

    const call = (
      {
        gateway,
        location,
      }: {
        gateway: { iolIdentity: string }
        location: { iolIdentity: string }
      },
      options?: MutationOptions<typeof AssociateLocationWithGatewayMutation>
    ) => {
      const data = {
        locationIolIdentity: location.iolIdentity,
        gatewayIolIdentity: gateway.iolIdentity,
      }

      const opts = addReport('Location associated with gateway', options)

      return mutate({ ...opts, variables: { data } })
    }

    return [call, state]
  }),

  // // Update existing location
  update: withReport<typeof UpdateLocationMutation>()((addReport) => {
    const [mutate, state] = useMutation(UpdateLocationMutation, {
      refetchQueries: [{ query: GetLocationsQuery }],
    })

    const call = (
      location: InputVariables<typeof UpdateLocationMutation>['data'],
      options?: MutationOptions<typeof UpdateLocationMutation>
    ) => {
      const opts = addReport('Location updated', options)

      const data = { ...location }
      return mutate({ ...opts, variables: { data } })
    }

    return [call, state] as const
  }),

  // Create or update location based on flag
  crup: (
    updateFlag = false,
    options?: MutationOptions<typeof CreateLocationMutation> &
      MutationOptions<typeof UpdateLocationMutation>
  ) => {
    const create = api.create(options)
    const update = api.update(options)
    return updateFlag ? update : create
  },

  saveLockAccessPolicy: withReport<typeof SaveLockAccessPolicyMutation>()(
    (addReport) => {
      const [mutate, state] = useMutation(SaveLockAccessPolicyMutation, {
        refetchQueries: [{ query: GetLocationsQuery }],
      })

      const call = (
        data: InputVariables<typeof SaveLockAccessPolicyMutation>['data'],
        options?: MutationOptions<typeof SaveLockAccessPolicyMutation>
      ) => {
        const opts = addReport('Lock access policy updated', options)

        return mutate({ ...opts, variables: { data } })
      }

      return [call, state]
    }
  ),
}

export default api
