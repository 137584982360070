import moment from 'moment'
import React from 'react'

// ---------------------- Strings -----------------------

export const shortenUUID = (uuid) => uuid.slice(0, uuid.indexOf('-'))

export const capitalize = (words) =>
  words
    ? words
        .trim()
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ')
    : ''

// Map object values to joined string
export const listString =
  (prop, join = '\n', none = 'None') =>
  (arr) =>
    arr?.length ? arr.map((v) => v[prop]).join(join) : none

// --------------------- Date and time ------------------

moment.locale('sv')

export const fromNow = (date) => (date ? moment(date).fromNow() : '-')

export const dayMonth = (date) => (date ? moment(date).format('ll') : '-')

export const dateAndTime = (date) =>
  date
    ? `${moment(date).format('YYYY-MM-DD')} ${moment(date).format('HH:mm')}`
    : '-'

export const dateOnly = (date) =>
  date ? moment(date).format('YYYY-MM-DD') : '-'

export const weekday = (date) => {
  if (date) {
    const wd = new Date(date)
      .toLocaleTimeString(moment.locale(), { weekday: 'long' })
      .split(' ')[0]
    return wd.charAt(0).toUpperCase() + wd.slice(1)
  }
  return '-'
}

export const timeValue = (date) => moment(date).valueOf()

// --------------------- Addresses ---------------------

const spaceOrBreak = (lines, line) => (line >= lines ? <>&nbsp;</> : <br />)

export const fullAddress = (
  { address1, zipCode, city, countryName, name }, // Address properties
  lines = 3
) => (
  <>
    {[name, address1, [zipCode, city].filter(Boolean).join(', '), countryName]
      .filter(Boolean)
      .map((value, i) => (
        <span key={i}>
          {capitalize(value)}
          {spaceOrBreak(lines, i + 1)}
        </span>
      ))}
  </>
)
