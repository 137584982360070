import React, { lazy } from 'react'
import {
  Button,
  useModal,
} from '@logtrade-technology-ab/logtrade-react-components'

import { Loading, CenterMessage, withSuspense } from 'components/layout'
import { ConnectionModal } from 'components/modals'
import api from 'api'

const ConnectionsOverview = withSuspense(lazy(() => import('./Overview')))

const NoConnectionsView = () => {
  const { showModal } = useModal()

  return (
    <CenterMessage
      icon="link"
      title="No connections, just yet."
      message="Access to other Logtrade Partners can provide a broader view on supply
        chains. Once a Request has been made, it will be visible here."
    >
      <Button onPress={() => showModal(ConnectionModal)}>
        Request data access
      </Button>
    </CenterMessage>
  )
}

const Overview = () => {
  const [connections, { loading, refetch }] = api.Connections.getAll()
  const anyConnections = !!connections.length

  if (anyConnections)
    return (
      <ConnectionsOverview
        connections={connections.filter((c) => !c.connectionToSelf)}
        refetch={refetch}
      />
    )

  if (loading) return <Loading />

  return <NoConnectionsView />
}

export default {
  Overview,
}
