import { matchRoutes, useLocation } from 'react-router-dom'
import useAppRoutes from 'useAppRoutes'

// Get all the currently active route objects from app routes
const useActiveRoutes = (absolute = true) => {
  // All active routes
  const routes = matchRoutes(useAppRoutes(absolute), useLocation()).map(
    ({ route }) => route
  )

  const [root] = routes

  // .reverse is in place
  const [current, parent] = [...routes].reverse()

  return {
    root,
    parent,
    current,
    routes,
  }
}

export default useActiveRoutes
