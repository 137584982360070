import React, { useEffect } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import AuthGate from 'features/AuthGate'
import { Login } from 'components/pages'
import Layout from 'components/layout'
import {
  useLogtradeAuth,
  ModalProvider,
  ModalRoot,
} from '@logtrade-technology-ab/logtrade-react-components'
import { ReportProvider } from 'features/ReportContext'
import { PageProvider } from 'features/PageContext'
import useAppRoutes from 'useAppRoutes'
import api from 'api'

export function App() {
  const { isAuthenticated, token, signOut } = useLogtradeAuth()

  if (isAuthenticated) api.setToken(token)

  const Logout = () => {
    useEffect(() => {
      api.purge()
      signOut()
    }, [])

    return <Navigate to="/login" />
  }

  const AppRoutes = () =>
    useRoutes([
      { path: 'login', element: <Login /> },
      { path: 'logout', element: <Logout /> },
      {
        element: <AuthGate />,
        children: [
          {
            element: <Layout />,
            children: useAppRoutes(),
          },
        ],
      },
    ])

  return (
    <ReportProvider>
      <ModalProvider>
        <ModalRoot />
        <React.StrictMode>
          <PageProvider>
            <AppRoutes />
          </PageProvider>
        </React.StrictMode>
      </ModalProvider>
    </ReportProvider>
  )
}

export default App
