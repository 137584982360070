import React from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useForm, useController } from 'react-hook-form'
import {
  Modal,
  Text,
  HR,
  TextField,
  Button,
} from '@logtrade-technology-ab/logtrade-react-components'
import { FormError } from 'components/elements'
import AddressChoice from './AddressChoice'
import { toLogtradeAddress, toMapboxAddress } from 'utils'
import { z } from 'zod'
import api from 'api'

const createSchema = z.object({
  name: z.string().min(1, {
    message: 'Location name is required',
  }),
  address1: z.string(),
  zipCode: z.string(),
  city: z.string(),
  countryCode: z.string(),
  address2: z.string().optional(),
  address3: z.string().optional(),
  province: z.string().optional(),
  gateCode: z.string().optional(),
  geoLocation: z
    .object({
      lat: z.number(),
      lng: z.number(),
    })
    .optional(),
  geoFenceRadius: z.number().int().optional(),
})

const updateSchema = z.object({
  id: z.string(),
  name: z.string().min(1, {
    message: 'Location name is required',
  }),
  address1: z.string(),
  zipCode: z.string(),
  city: z.string(),
  countryCode: z.string(),
  address2: z.string().optional(),
  address3: z.string().optional(),
  province: z.string().optional(),
  gateCode: z.string().optional(),
  geoLocation: z
    .object({
      lat: z.number(),
      lng: z.number(),
    })
    .optional(),
  geoFenceRadius: z.number().int().optional(),
})

const LocationModal = ({ location, onClose, ...props }) => {
  const [handleLocation, { loading }] = api.Locations.crup(location, {
    onError: ({ message }) => setError('request', { message }),
    onCompleted: onClose,
  })

  const schema = location ? updateSchema : createSchema

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm(
    location && {
      defaultValues: {
        name: location.name,
        address: toMapboxAddress(location),
      },
    }
  )

  const startLocation = location ? toMapboxAddress(location).coords : null

  const { field } = useController({
    control,
    name: 'address',
    rules: { required: true },
  })

  const onSubmit = (formData) => {
    const addressData = toLogtradeAddress(formData.address)

    const input = {
      id: location?.id,
      name: formData.name,
      ...addressData,
    }

    const { success, error, data } = schema.safeParse(input)

    if (error) {
      error.issues.forEach(({ path: [field], message }) =>
        setError(field, { message })
      )
      console.log(error)
    }

    return success && handleLocation(data)
  }

  return (
    <Modal
      title={location ? 'Edit location' : 'Add new location'}
      onClose={onClose}
      className="lg:!w-[900px] md:!w-[700px] sm:!w-[500px]"
      isDismissable
      {...props}
    >
      <HR />
      <form className="grid p-8 mb-3 gap-5" onSubmit={handleSubmit(onSubmit)}>
        <AddressChoice
          // disabled={!!location}
          startLocation={startLocation}
          {...field}
        />
        <TextField.Control
          control={control}
          name="name"
          placeholder="Location name"
        />
        <FormError error={errors.request} />
        <div className="grid grid-cols-[1fr_1fr_auto]">
          <Text className="whitespace-nowrap !text-xs">
            {field.value?.address_line1}
            <br />
            {field.value?.description}
          </Text>
          <Text className="whitespace-nowrap !text-xs">
            {field.value?.coords.latitude}
            <br />
            {field.value?.coords.longitude}
          </Text>
          <Button
            type="submit"
            size="sm"
            isLoading={loading}
            onPress={() => clearErrors('request')}
            className="align-self-end"
          >
            {location ? 'Update location' : 'Add location'}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

LocationModal.propTypes = {
  location: PropTypes.object,
  ...Modal.propTypes,
}

export default LocationModal
