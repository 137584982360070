import { useEffect, useState } from 'react'

// Polling utility
const usePoller = (request, { interval = 2000, condition = false }) => {
  const [intId, setIntId] = useState(null)

  const startPolling = () => {
    const id = setInterval(request, interval)
    setIntId(id)
    return id
  }
  const stopPolling = () => {
    clearInterval(intId)
    setIntId(null)
  }

  useEffect(() => {
    if (condition) {
      const id = startPolling()
      return () => clearInterval(id)
    }
  }, [condition])

  return { startPolling, stopPolling, isPolling: !!intId }
}

export default usePoller
