import {
  ConnectOrganicationToIolMutation,
  CreateUserMutation,
  RemoveUserMutation,
  SaveOrganizationSettingsMutation,
} from './mutations'
import { GetOrganizationQuery, GetUsersQuery } from './queries'
import { GetConnectionsQuery } from '../connections/queries'
import { useQuery, useMutation } from '@apollo/client'
import { withReport } from 'api/utils'
import { InputVariables, MutationOptions, QueryOptions } from 'api/types'
export * from './queries'
export * from './mutations'
import mockApi from './mocks'

const api = {
  // Get organization
  get: (options?: QueryOptions<typeof GetOrganizationQuery>) => {
    const state = useQuery(GetOrganizationQuery, options as unknown)
    const organization = state.data?.organization ?? {}

    return [organization, state] as const
  },

  // Get organization users
  getUsers: (options?: QueryOptions<typeof GetUsersQuery>) => {
    const state = useQuery(GetUsersQuery, options as unknown)
    const users = state.data?.organization?.users ?? []

    return [users, state] as const
  },

  // Check if the module codes are fetched
  finished: (options?: QueryOptions<typeof GetOrganizationQuery>) => {
    const state = useQuery(GetOrganizationQuery, {
      fetchPolicy: 'cache-only',
      ...(options as object),
    })

    const { hasConnectedHubs, hasConnectedProducts, hasConnectedTrucks } =
      state.data?.organization ?? {}

    return (
      hasConnectedHubs !== undefined &&
      hasConnectedProducts !== undefined &&
      hasConnectedTrucks !== undefined &&
      !state.loading
    )
  },

  // Create a new user
  createUser: withReport<typeof CreateUserMutation>()((addReport) => {
    const [mutate, state] = useMutation(CreateUserMutation, {
      refetchQueries: [{ query: GetUsersQuery }],
    })

    const call = (
      user: InputVariables<typeof CreateUserMutation>['data'],
      options?: MutationOptions<typeof CreateUserMutation>
    ) => {
      const opts = addReport(`User ${user.email} created`, options)

      return mutate({ ...opts, variables: { data: user } })
    }

    return [call, state] as const
  }),

  // Remove existing user
  removeUser: withReport<typeof RemoveUserMutation>()((addReport) => {
    const [mutate, state] = useMutation(RemoveUserMutation, {
      refetchQueries: [{ query: GetUsersQuery }],
    })

    const call = (
      { id, email }: { id: string; email: string },
      options?: MutationOptions<typeof RemoveUserMutation>
    ) => {
      const opts = addReport(`User ${email} deleted`, options)

      return mutate({ ...opts, variables: { data: { userId: id } } })
    }

    return [call, state] as const
  }),

  // Connect orgianization to IOL
  connectToIol: withReport<typeof ConnectOrganicationToIolMutation>()(
    (addReport) => {
      const [mutate, state] = useMutation(ConnectOrganicationToIolMutation, {
        refetchQueries: [{ query: GetConnectionsQuery }],
      })

      const call = (
        options?: MutationOptions<typeof ConnectOrganicationToIolMutation>
      ) => {
        const data = {
          // eslint-disable-next-line no-undef
          iolBaseUrl: process.env.REACT_APP_IOL_API_URL,
        }
        const opts = addReport('Organization connected to IOL', options)

        return mutate({ ...opts, variables: { data } })
      }

      return [call, state]
    }
  ),

  // Connect orgianization to IOL
  updateSettings: withReport<typeof SaveOrganizationSettingsMutation>()(
    (addReport) => {
      const [mutate, state] = useMutation(SaveOrganizationSettingsMutation, {
        refetchQueries: [{ query: GetOrganizationQuery }],
      })

      const call = (
        {
          disconnectFromSensor,
          disconnectFromLocationIOLId,
        }: {
          disconnectFromSensor: string
          disconnectFromLocationIOLId: string | null
        },
        options?: MutationOptions<typeof SaveOrganizationSettingsMutation>
      ) => {
        const opts = addReport(
          `Disconnect set too ${disconnectFromSensor} deleted`,
          options
        )

        return mutate({
          ...opts,
          variables: {
            data: { disconnectFromSensor, disconnectFromLocationIOLId },
          },
        })
      }
      return [call, state]
    }
  ),
}

export default Object.assign(api, {
  mock: mockApi,
})
