import React from 'react'
import { MapboxOverlay } from '@deck.gl/mapbox'
import { useControl } from 'react-map-gl'
import { DeckProps } from '@deck.gl/core'

const DeckGLOverlay = React.memo(function DeckGLOverlay(props: DeckProps) {
  const overlay = useControl<MapboxOverlay>(
    () => new MapboxOverlay({ interleaved: true, ...props })
  )

  React.useEffect(() => {
    overlay.setProps(props)
  }, [props, overlay])

  return null
})

export default DeckGLOverlay
