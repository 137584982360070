import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import {
  HR,
  TextField,
  Button,
} from '@logtrade-technology-ab/logtrade-react-components'
import { FormError } from 'components/elements'
import api from 'api'

const VolvoLogin = ({ setTitle }) => {
  useEffect(() => setTitle('Login to sync your Volvo account'), [setTitle])

  const [saveCredentials, { loading }] = api.Volvo.login({
    onError: ({ message }) => setError('request', { message }),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm()

  const onSubmit = saveCredentials

  return (
    <>
      <HR />
      <div className="flex justify-center items-center min-h-[300px]">
        <form
          className="grid p-5 mb-3 gap-3 w-[300px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField.Control
            control={control}
            name="username"
            placeholder="Username"
            isRequired
          />
          <TextField.Control
            control={control}
            type="password"
            name="password"
            placeholder="Password"
            isRequired
          />
          <FormError errors={errors.request} />
          <Button
            type="submit"
            size="sm"
            isLoading={loading}
            onPress={() => clearErrors('request')}
          >
            Sign in
          </Button>
        </form>
      </div>
    </>
  )
}

VolvoLogin.propTypes = {
  setTitle: PropTypes.func,
}

export default VolvoLogin
