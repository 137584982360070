import React from 'react'
import PropTypes from 'prop-types'
import {
  HeroIcon,
  Text,
} from '@logtrade-technology-ab/logtrade-react-components'

const CenterMessage = ({ icon, title, message, children }) => (
  <div className="flex flex-col grow justify-center items-center p-5 !pb-8">
    <div className="grid justify-items-center auto-rows-min grid-rows-[100px_16px] grid-cols-1 max-w-[380px] gap-y-6">
      <HeroIcon icon={icon} className="h-full" />
      <Text tag="h4" className="text-center">
        {title}
      </Text>
      <Text className="text-center">{message}</Text>
      {children}
    </div>
  </div>
)

CenterMessage.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.any,
}

export default CenterMessage
