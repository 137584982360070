import { useQuery } from '@apollo/client'
import { GetSensorsQuery, GetSensorQuery } from './queries'
export * from './queries'
import { QueryOptions } from 'api/types'

const api = {
  // Get single sensor by ID
  getById: (
    variables: { id: string },
    options?: QueryOptions<typeof GetSensorQuery>
  ) => {
    const state = useQuery(GetSensorQuery, {
      ...(options as object),
      variables,
    })
    const sensor = state.data?.sensor

    return [sensor, state]
  },

  // Get all sensors
  getAll: (options?: QueryOptions<typeof GetSensorQuery>) => {
    const state = useQuery(GetSensorsQuery, options as unknown)
    const sensors = state.data?.sensors ?? []
    return [sensors, state]
  },
}

export default api
