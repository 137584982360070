import { GetFreeGatewaysQuery, GetGatewaysQuery } from './queries'
import { RemoveGatewayAssociationMutation } from './mutations'
import { withReport } from 'api/utils'
import { useMutation, useQuery } from '@apollo/client'
import { GetLocationsQuery } from 'api/locations'
import { GetVehiclesQuery } from 'api/vehicles'
import { MutationOptions, QueryOptions } from 'api/types'

export * from './queries'
export * from './mutations'

const api = {
  // Get all gateways
  getAll: (options: QueryOptions<typeof GetGatewaysQuery>) => {
    const state = useQuery(GetGatewaysQuery, options as unknown)
    const gateways = state.data?.gateways ?? []

    return [gateways, state] as const
  },

  // Get unassociated gateways
  getFree: (options: QueryOptions<typeof GetFreeGatewaysQuery>) => {
    const state = useQuery(GetFreeGatewaysQuery, options as unknown)
    const gateways = state.data?.readFreeGateways ?? []

    return [gateways, state] as const
  },

  // Remove gateway association
  removeAssociation: withReport<typeof RemoveGatewayAssociationMutation>()(
    (addReport) => {
      const [mutate, state] = useMutation(RemoveGatewayAssociationMutation, {
        refetchQueries: [
          { query: GetLocationsQuery },
          { query: GetGatewaysQuery },
          { query: GetVehiclesQuery },
        ],
      })

      const call = (
        {
          gateway,
          location,
          vehicle,
        }: {
          gateway: { iolIdentity: string }
          location?: { iolIdentity: string }
          vehicle?: { iolIdentity: string }
        },
        options: MutationOptions<typeof RemoveGatewayAssociationMutation>
      ) => {
        const data = {
          gatewayIolIdentity: gateway.iolIdentity,
          associatedLocationIolIdentity: location?.iolIdentity,
          associatedVehicleIolIdentity: vehicle?.iolIdentity,
        }

        const opts = addReport('Gateway association cleared', options)

        return mutate({ ...opts, variables: { data } })
      }

      return [call, state] as const
    }
  ),
}

export default api
