import { OperationType } from 'api/types'
import { GetOrganizationQuery } from './queries'

const mockApi = {
  get: (
    organization: OperationType<typeof GetOrganizationQuery>['organization']
  ) => ({
    request: {
      query: GetOrganizationQuery,
    },
    result: {
      data: {
        organization: {
          __typename: 'Organization',
          id: 'whatever',
          name: 'whatever',
          customerNumber: 0,
          hasConnectedTrucks: false,
          hasConnectedProducts: false,
          hasConnectedHubs: false,
          isConnectedToIol: true,
          ...organization,
        },
      },
    },
  }),
}

export default mockApi
