import { graphql } from 'gql'

export const RemoveGatewayAssociationMutation = graphql(`
  mutation RemoveGatewayAssociation($data: RemoveGatewayAssociationInput!) {
    removeGatewayAssociation(data: $data) {
      gateway {
        id
      }
    }
  }
`)
