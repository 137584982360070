import { DocumentNode, useQuery } from '@apollo/client'
import {
  GET_TRADE_UNITS_PAGINATION,
  GetSmallTradeUnitsQuery,
  GetTradeUnitQuery,
  GetTradeUnitsCountQuery,
} from './queries'
import { QueryOptions } from 'api/types'

const api = {
  // Get single tradeUnit by ID
  getById: (
    variables: { id: string },
    options?: QueryOptions<typeof GetTradeUnitQuery>
  ) => {
    const state = useQuery(GetTradeUnitQuery, {
      ...(options as object),
      variables,
    })
    const tradeUnit = state.data?.tradeUnit
    return [tradeUnit, state] as const
  },

  // Get all tradeUnits
  getAllSmall: (options?: QueryOptions<typeof GetSmallTradeUnitsQuery>) => {
    const state = useQuery(
      GetSmallTradeUnitsQuery as DocumentNode,
      options as object
    )
    const tradeUnits = state.data?.tradeUnits ?? []

    return [tradeUnits, state] as const
  },

  // Get tradeUnits with pagination
  getWithPagination: (
    variables: {
      page: number
      pageSize: number
      orderBy: string
      orderDirection: 'ASC' | 'DESC'
      search?: string
      category?: 'All' | 'Active' | 'Inactive'
    },
    options?: QueryOptions<typeof GET_TRADE_UNITS_PAGINATION>
  ) => {
    const state = useQuery(GET_TRADE_UNITS_PAGINATION as DocumentNode, {
      ...(options as object),
      variables,
    })

    const tradeUnits = state.data?.tradeUnitsWithPagination ?? []
    return [tradeUnits, state] as const
  },

  getTradeUnitsCount: (search?: string) => {
    const { data, loading, error } = useQuery(GetTradeUnitsCountQuery, {
      variables: { search },
    })

    const count = data?.tradeUnitsCount
    return { count, loading, error }
  },
}

export default api
