import { graphql } from 'gql'

export const CreateVehicleMutation = graphql(`
  mutation CreateVehicle($data: CreateVehicleInput!) {
    createVehicle(data: $data) {
      ...FullVehicleFragment
    }
  }
`)

export const UpdateVehicleMutation = graphql(`
  mutation UpdateVehicle($data: UpdateVehicleInput!) {
    updateVehicle(data: $data) {
      ...FullVehicleFragment
    }
  }
`)

export const AssociateVehicleWithGatewayMutation = graphql(`
  mutation AssociateVehicleWithGateway(
    $data: AssociateVehicleWithGatewayInput!
  ) {
    associateVehicleWithGateway(data: $data) {
      entityId
      associatedIolIdentity
    }
  }
`)

export const RemoveVehicleMutation = graphql(`
  mutation RemoveVehicle($data: RemoveVehicleInput!) {
    removeVehicle(data: $data) {
      id
    }
  }
`)
