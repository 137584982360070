import React from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'
import {
  Text,
  TextField,
  Pane,
} from '@logtrade-technology-ab/logtrade-react-components'

const Overview = ({
  children,
  className,
  title,
  searchValue,
  setSearchValue,
}) => (
  <Pane>
    <div
      className={twMerge(
        'grid h-full gap-5 grid-rows-[auto_40px_1fr]',
        className
      )}
    >
      <div className="flex justify-between items-center">
        <Text tag="h3">{title}</Text>
        {setSearchValue && (
          <TextField
            placeholder="Search"
            value={searchValue}
            onChange={setSearchValue}
            aria-label="search"
          />
        )}
      </div>
      {children}
    </div>
  </Pane>
)

Overview.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  title: PropTypes.string,
}

export default Overview
